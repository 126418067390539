.modal-product {
    .step-caract {

        display: grid;
        grid-template-columns: repeat(2, 300px) 1fr;
        gap: 30px;

        height: 100%;
        width: 100%;

        .col-caract {
            display: flex;
            flex-direction: column;
            gap: 10px;

            background-color: #FFFFFF;
            border-radius: 10px;
            box-shadow: rgba(0,0,0,.05) 0 0 25px 0;
            padding: 1.5rem;
        }

        .item-weight,
        .item-dimension {
            display: grid;
            grid-template-columns: auto 100px;
            gap: 10px;
        }

        .step-modal-form-item {
    
            display: flex;
            flex-direction: column;
            gap: 0.35rem;
            margin-bottom: 1rem;
    
            .step-modal-form-item-label {
                font-weight: 600;
                font-size: 12px;
                margin-bottom: 0;
                color: #374151;
            }
        }
    
        ap-checkbox {
            label {
                font-size: 12px !important;
            }
        }
    }

}