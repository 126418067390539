.modal-product {

    .step-provider {

        height: 100%;
        width: 100%;

        display: flex;

        gap: 1.5rem;

        overflow: auto;


        /* -------------------------------------------------------------------------- */
        /*                       Structure globale de la modale                       */
        /* -------------------------------------------------------------------------- */

        .step-modal-left {
            height: 100%;

            width: 250px;
            max-width: 250px;
            min-width: 200px;
        }
        
        .step-modal-center {
            height: 100%;

            width: 100%;
            min-width: 600px;
        }
        
        .step-modal-right {
            height: 100%;

            width: 350px;
            max-width: 350px;
            min-width: 250px;
        }

        .step-modal-card {
            background-color: #FFFFFF;
            border-radius: 10px;
            box-shadow: rgba(0,0,0,.05) 0 0 25px 0;
            padding: 1.5rem;
            width: 100%;

            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            overflow: auto;
        }

        .step-modal-card-title {
            color: #25213B;
            font-size: 16px;
            font-weight: 600;
        }

        .step-modal-form-items-cols {
            display: flex;
            gap: 1.5rem;
        }

        .step-modal-form-items-col {
            display: flex;
            flex-direction: column;

            width: 100%;
        }
        
        .step-modal-form-item {

            display: flex;
            flex-direction: column;
            gap: 0.35rem;
            margin-bottom: 1rem;

            &.invalid {
                .step-modal-form-item-label {
                    color: var(--color-form-invalid);
                }

                input {
                    border-color: var(--color-form-invalid);
                }
            }

            .step-modal-form-item-label {
                font-weight: 600;
                font-size: 12px;
                margin-bottom: 0;
                color: #374151;
            }
        }

        .step-modal-separator-horizontal {
            width: 100%;
            height: 1px;
            background-color: #D4D7DB;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }

        /* -------------------------------------------------------------------------- */
        /*                                Partie gauche                               */
        /* -------------------------------------------------------------------------- */
        
        .step-modal-left {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        .list-card {
            &.isDefault {
                .list-card-left {
                    display: flex !important;
                }
            }

            &.invalid-provider {
                color: red;
            }

            .list-card-left {
                min-width: 14px;
                display: none !important;
            }
    
            .list-card-center {
                overflow: hidden;
                width: 100%;
    
                span {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
        

        .providers-list-title {
            font-weight: 600;
            font-size: 16px;
            color: #25213B;
        }
        
        .providers-list-content {
            display: flex;
            flex-direction: column;
            overflow: auto;
            height: 100%;
        }

        .providers-actions {
            min-height: 150px;
            height: 100%;
        }

        #add-provider {
            font-size: 14px;
            font-weight: 500;
        }


        /* -------------------------------------------------------------------------- */
        /*                               Partie centrale                              */
        /* -------------------------------------------------------------------------- */

        
        .step-modal-center {
            display: flex;
            gap: 1.5rem;
            
            .step-modal-card {
                width: 100%;
                overflow: auto;
            }

			#no-provider {
				display: flex;
				align-items: center;
				justify-content: center;
				color: lightgrey;
			}
        }

        #price-form-title.invalid{
            color: var(--color-form-invalid);
        }

        #default-provider-form-item {
            display: flex;
            gap: 0;
            flex-direction: row;
            justify-content: space-between;
        }
        
        #delete-provider {
            font-size: 12px;
            font-weight: 500;
        }


        /* -------------------------------------------------------------------------- */
        /*                                Partie droite                               */
        /* -------------------------------------------------------------------------- */
        
        
        .step-modal-right {
            display: flex;
            flex-direction: column;
            gap: 2rem;

            border-left: 1px solid #D9D9D9;
            padding-left: 1.5rem;

            overflow: auto;
        }

        .step-modal-right-part {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        .recommended-sell-prices {

			background-color: #FFFFFF;
            border-radius: 10px;
            box-shadow: rgba(0,0,0,.05) 0 0 25px 0;
            padding: 1.5rem;
            width: 100%;

            overflow: auto;
            display: flex;
            flex-direction: column;

            gap: 1rem;

            .recommended-sell-price {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1.5rem;
                font-size: 12px;

                .recommended-sell-price-provider-name {
                    color: #25213B;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .recommended-sell-price-value {
                    font-weight: 600;
                    color: #25213B;
                    white-space: nowrap;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid #D4D7DB;
                    padding-bottom: 1rem;
                }

            }

        }
        


        /* -------------------------------------------------------------------------- */
        /*                             Les cards en liste                             */
        /* -------------------------------------------------------------------------- */
    
        .list-cards {
            //Variables css
            --apps-list-cards-background-color: #FFFFFF;
            --apps-list-cards-color: #000000;

            display: flex;
            flex-direction: column;
            background-color: var(--apps-list-cards-background-color);
            color: var(--apps-list-cards-color);
            border-radius: 10px;
            box-shadow: rgba(0,0,0,.05) 0 0 25px 0;

            &+.list-cards {
                margin-top: 1rem;
            }
        }

        .list-card {
            --apps-list-card-selected-background-color: rgba(52, 117, 248, 0.20);
            --apps-list-card-active-background-color: rgba(33, 150, 243, 0.3);
            --apps-list-card-hover-background-color: rgba(33, 150, 243, 0.1);

            display: flex;
            align-items: center;
            gap: 1rem;

            padding: 0.65rem 1rem;
            border-bottom: 1px solid var(--AppBackgroundColor);
            height: 50px;

            font-weight: 500;

            .list-card-left {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
            }

            .list-card-center {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
            }

            .list-card-right {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
            }

            .list-card-arrow {
                display: flex;
                align-items: center;
                visibility: hidden;
            }

            &.list-card-selected {
                color: #3475F8;
                background-color: var(--apps-list-card-selected-background-color);

                .list-card-arrow {
                    visibility: visible;
                }
            }

            &:first-child {
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
            }

            &:last-child {
                border-bottom: 0;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            &:not(.readonly):hover {
                cursor: pointer;
                background-color: var(--apps-list-card-hover-background-color);
            }

            &:not(.readonly):active,
            &:not(.readonly):focus {
                background-color: var(--apps-list-card-active-background-color);
            }

            &:not(:last-child) {
                border-bottom: 1px solid #F2F1F6;
            }
        }

    }
}