ap-edit-product-stock-readonly {

    border: 1px solid #DFE4EA;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    

    &.active {
        .stock-title {
            #icon-active {
                transform: rotate(0);
            }
        }
        .stock-content {
            display: block;
        }
    }

    .stock-title {
        background: #F1F1F1;
        font-size: 1.5rem;
        padding: 0.5rem 1rem;
        font-weight: 600;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: space-between;

        #icon-active {
            transform: rotate(180deg);
            transition: transform 0.3s;
        }
    }

    .stock-content {
        display: none;
        padding: 1rem;

        .page-card {
            border-bottom: none !important;
            padding: 0;
        }

        .custom-alarm {
            display: flex;
            align-items: center;
            gap: 0.25rem;

            #enableCustomAlarm {
                font-weight: bold;

                &[data-enabled="true"] {
                    color: #4caf50; 
                }

                &[data-enabled="false"] {
                    color: #f44336; 
                }
            }
        }
    }
}