.edit-group-quote {

    .modal-header {

        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 1rem;

        >div {
            display: flex;
            gap: 10px;
            width: 100%;
        }


        #infos_quote {
            flex-grow: 1;
            display: flex;
            height: 100%;
            align-items: center;
            white-space: nowrap;
        }

        .modal-title-right {
            white-space: nowrap;
            color: #6c757d;
            gap: 10px;
            display: flex;
            margin-left: auto;

            #oldPrice {
                color: #03a9f4;
            }

            .line {
                border-right: 1px solid #D9D9D9;
            }

            #newPrice {
                color: #43a047;
            }
        }
    }

    .modal-body {
        background: #f2f1f7;
        padding: 2rem !important;
    }

}