ap-alert {

    overflow: hidden;
    display: block;

    height: 0;
    transition: height 0.5s ease-out;

    --border-radius: 2px;

    .content {

        padding: 16px;
        width: 100%;

        background-color: var(--background-color);

        border: 1px solid var(--border-color);
        border-radius: var(--border-radius);

        color: var(--color);

        .title {
            font-weight: bold;
        }

    }

    &.visible {
        height: var(--calc-height);
        transition: height 0.5s ease-in;
    }

    &[type="danger"] {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }

    &[type="warning"] {
        --background-color: #FFF3CD;
        --border-color: #FFEEBA;
        --color: #856404;
    }
    
    &[type="success"] {
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }

    &[type="info"] {
        color: #0c5460;
        background-color: #d1ecf1;
        border-color: #bee5eb;
    }
    
    &[type="primary"] {
        color: #004085;
        background-color: #cce5ff;
        border-color: #b8daff;
    }

    &[type="secondary"] {
        color: #383d41;
        background-color: #e2e3e5;
        border-color: #d6d8db;
    }
    
    &[type="light"] {
        color: #818182;
        background-color: #fefefe;
        border-color: #fdfdfe;
    }

    &[type="dark"] {
        color: #1b1e21;
        background-color: #d6d8d9;
        border-color: #c6c8ca;
    }

}