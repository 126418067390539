body {
    &:has(.select-providers-modal.open) {

        position: relative;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 17000;

            content: ' ';
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.20) 100%);
        }

    }
}

ap-select-providers {
    .active {
        --background: rgba(52, 117, 248, 0.1) !important;
        --color: #3475F8 !important;
    }
}

.select-providers-modal {
    width: 26rem;
    position: fixed;
    left: -100%;
    top: calc(10px + var(--ap-navbar-height));
    display: flex;
    flex-direction: column;
    bottom: 10px;
    z-index: 17001;

    transition: left 0.3s;

    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px -5px #000000;

    &.open {
        left: calc(10px + var(--ap-aside-width));
    }

    #search {
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .title {
        font-weight: 700;
        font-size: 1.5rem;
        padding: 1rem;
        display: flex;
        align-items: center;

        ap-button {
            margin-left: auto;
        }
    }

    .content {
        flex-grow: 1;
        overflow: auto;

        .item,
        .item-title {
            position: relative;
            padding: 1rem;
            display: flex;
            align-items: center;

            &.selected {
                background-color: #e6f0ff;
            }
        }

        .item:has(+ .item-title) {

            margin-bottom: calc(1px + 2 * 5px);

            &::after {
                content: '';
                position: absolute;
                bottom: -5px;
                left: 1rem;
                width: calc(100% - 1rem * 2);
                height: 1px;
                background-color: #cbcbcb;
            }
        }

        .item-title {
            color: #2c2c2c;
            font-weight: 700;
            font-size: 1.2rem;
            gap: 10px;

            ap-icon {
                color: #8c8c8c;
                font-size: 20px;
            }
        }


        .item {
            color: #8c8c8c;
            cursor: pointer;

            &:hover {
                color: #2c2c2c;
                background-color: #F5F9FF;
            }

            ap-icon {
                margin-left: auto;
                font-size: 20px;
            }
        }
    }
}