.modal {
    textarea {
        max-height: 200px;
    }
}


.modal-open {
    .modal {
        overflow-x: hidden;
        overflow-y: hidden;
    }
}

.modal-content {
    .modal-header {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    border-radius: 10px;
}

.modal-nav {
    padding: 0 !important;
}

.modal-header {
    border-bottom: none;
    padding: 2rem;

    .modal-title {
        font-size: 1.5rem;
        font-weight: bold;
    }
}

.modal-body {
    padding: 0rem 2rem 2rem 2rem;
}

.pt-modal-body {
    padding-top: 2rem;
}