.page-settings-compta {

    .activate-module {
        width: 100%;
        display: flex;
        flex-direction: column;

        .container-switch {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
        }

        ap-switch {
            margin-left: 10px;
        }

        ap-button {
            margin-left: auto;
        }
    }

    .line {
        margin-bottom: 20px;
        width: 100%;
        border-bottom: 1px solid #D9D9D9;
    }

    .default {
        display: flex;
        align-items: center;

        .text {
            width: 100px;
        }

        .select {
            width: 200px;
        }
    }


    #grid-account,
    #grid-taxe {
        height: 300px;
    }

    .account {
        border-bottom: 1px solid #D9D9D9;

        .default-account {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding-bottom: 20px;
        }
    }

    .taxe {
        margin-top: 20px;

        .default-taxe {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding-bottom: 20px;
        }
    }

    .taxe,
    .account {
        gap: 20px;
        display: flex;
        flex-direction: column;
    }

}