core-aside {
    top: calc(2 * var(--ap-navbar-height)) !important;
    height: calc(100% - 2 * var(--ap-navbar-height)) !important;

    box-shadow: none !important;
}

core-aside-toggler {
    position: absolute;
    top: var(--ap-navbar-height);
    box-shadow: none !important;

    .aside-toggler-label {
        font-size: 14px !important;
        padding-left: 7px;
        color: rgb(255 255 255 / 41%) !important;
    }

    .icon {
        box-shadow: none !important;

        &:before {
            content: '';
            height: 100000px;
            position: absolute;
            top: 0;
            right: -6px;
            background: linear-gradient(90deg, rgb(0 0 0 / 27%) 0%, rgb(0 0 0 / 0%) 100%);
            width: 6px;
        }
    }
}

#aside {
    &.active {
        overflow: auto !important;
    }

    .aside-nav {
        a {
            cursor: pointer;
            text-decoration: none !important;
        }

        .separator {
            margin-left: 0.7rem;
            margin-right: 0.7rem;

            transition: margin-left 0.1s, margin-right 0.1s;

            &::before,
            &::after {
                min-width: 5.25rem !important;
            }

            .label {
                font-size: 1.1rem;
                margin-right: 1.5rem !important;
                margin-left: 1.5rem !important;
            }
        }
    }

    /*Correction pb du core qui ne met pas tout le temps la class hover*/
    aside-navigation-link {
        &:hover {
            >a {
                background-color: var(--ap-aside-link-hover-background) !important;
                font-weight: var(--ap-aside-link-hover-font-weight) !important;
                color: var(--ap-aside-link-hover-color) !important;
            }
        }
    }

    /* Accéleration de l'animation du logo */
    core-aside-logo {
        display: block;
        transition: all 0s;
        overflow: hidden;
        padding-top: 50px;
        margin-top: calc(-50px + 0.5em);
        pointer-events: none;
    }

    core-aside-logo svg {
        transition: all 100ms;
        width: calc(var(--ap-aside-hover-width) - 100px);
        margin-bottom: 5px;
        overflow: visible;
    }

    core-aside-logo .mask {
        fill: var(--ap-aside-background) !important;
        transition: all 0s;
        transform: translateX(0);
    }

    core-aside-logo .letters {
        fill: var(--ap-aside-color);
    }

    core-aside-logo.active svg {
        transition: all 100ms;
        width: calc(var(--ap-aside-hover-width) - 60px);
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 10px;
    }

    core-aside-logo.active .left-mask {
        transition: all 6000ms 50ms;
        transform: translateX(-100vw);
    }

    core-aside-logo.active .right-mask {
        transition: all 2000ms 50ms;
        transform: translateX(100vw);
    }
}

/* Masquage du bouton de déconnexion */
#aside-bottom-content {
    &>.mt-2.px-2.show-when-active {
        display: none !important;
    }
}

.aside-logo {
    z-index: 1;
    padding-top: 10px;
    padding-bottom: 5px;
}