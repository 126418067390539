.modal-create-order-by-product {

    .modal-content {
        display: flex;
        flex-direction: row;

        .display-products {
            width: 30%;
            z-index: 2;
            min-width: 300px;
            position: relative;

            margin: 2rem 0 2rem 2rem;
            padding: 20px;
            overflow: auto;

            &:has(.loading) {
                overflow: hidden;
            }

            border: 1px solid #D4D7DB;
            background: #FFF;

            .productProviders {
                display: flex;
                flex-direction: column;
                gap: 20px;
            }

            .container-no-value {
                display: none;
            }

            &.no-value {
                .container-no-value {
                    position: absolute;
                    top: 0;
                    left: 0;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    height: 100%;
                    width: 100%;

                    background-color: #F5F9FF;
                    border-radius: 5px;
                    color: #647795;

                    font-weight: 700;
                    font-size: 30px;
                    z-index: 2;
                }
            }

        }

        .title-product {
            font-weight: 700;
            font-size: 16px;
            color: black;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .edit-content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .form-container {
                display: grid;
                grid-template-rows: repeat(2, 1fr);
                grid-template-columns: repeat(3, 16%) auto;

                gap: 10px;


                #provider-container {
                    grid-area: 1 / 1 / 2 / 2;
                }

                #order-container {
                    grid-area: 1 / 2 / 2 / 3;
                }

                #label-container {
                    grid-area: 2 / 1 / 3 / 4;
                }

                #comment-container {
                    grid-area: 1 / 4 / 3 / 5;

                    display: flex;
                    flex-direction: column;

                    textarea {
                        flex-grow: 1;
                    }
                }

            }

            .container-product {
                display: flex;
                flex-direction: column;
            }

            .title-quote {
                font-weight: bold;
                font-size: 18px;
                color: #4E4E4E;
                margin-bottom: 10px;
            }

        }
    }

}