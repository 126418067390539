#open-documents-container {
    width: 26rem;
    padding: 1rem;
    display: none;
    transition: right 500ms;
    overflow: hidden;
    box-shadow: 0px 0px 10px -5px #000000;
    border-radius: 10px;
    background-color: white;
    color: black;
    flex-direction: column;
    margin: 10px;

    &.active {
        display: flex;
        right: 0px;
    }

    #filters {

        display: flex;
        color: #acacac;

        margin-left: 0.25rem;
        margin-right: 0.25rem;

        [data-filter] {
            cursor: pointer;
            text-align: center;

            border-bottom: 2px solid #acacac;
            font-weight: bold;

            padding: 0.5rem;

            display: flex;
            align-items: center;
            justify-content: center;

            flex: 1 1 auto;

            transition: 0.2s;

            .badge {
                margin-left: 5px;
                background: #F2F2F2;
                color: #acacac;
                border-radius: 5px;
            }

            &:hover,
            &.active {
                border-bottom-color: black;
                color: black;

                .badge {
                    background: black;
                    color: white;
                }
            }

        }
    }

    .open-documents-item {
        position: relative;
        display: flex;
        width: 100%;
        border-bottom: 1px solid #e3e3e3;
        padding: 0.5rem;
        padding: 1rem;
        margin-bottom: 0.5rem;
    }

    .open-documents-item-title {
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
    }


    .open-documents-item-buttons {
        right: 1rem;
        top: 1rem;

        button {
            border: 0;
            background-color: inherit;
            border-radius: 100%;
            width: 30px;
            height: 30px;
            transition: 0.1s;

            &:hover {
                background-color: #b4b4b480;
            }
        }

    }

}

ap-open-documents {
    position: relative;
}

.mobile {

    #notif-container {
        top: 0;
        width: calc(100% - 20px)
    }

}