.productCellRenderer {

    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;

    &.error {
        color: red;
        font-weight: bold;
    }

    &.no-text {
        .text {
            display: none;
        }
    }

    .fake-image {
        display: flex;
        height: 34px;
        width: 34px;
        min-height: 34px;
        min-width: 34px;
        border: 1px dashed #e1e1e1;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #e1e1e1;
    }

    .image {
        display: flex;
        object-fit: contain;
        height: 34px;
        width: 34px;
        background: white;
        padding: 2px;
        border-radius: 5px;
        cursor: pointer;
    }

    #loading-spinner {
        display: flex;
        height: 34px;
        width: 34px;
        min-height: 34px;
        min-width: 34px;
        align-items: center;
        justify-content: center;
        color: #a7a7a7;
        border: 1px dashed #e1e1e1;
        border-radius: 5px;
        font-size: 25px;
    }

    &.displayImage {

        .fake-image {
            display: none !important;
        }

        #loading-spinner {
            display: none !important;
        }
    }

    &.displayDefault {
        .image {
            display: none !important;
        }

        #loading-spinner {
            display: none !important;
        }
    }

    &.displaySpinner {
        .image {
            display: none !important;
        }

        .fake-image {
            display: none !important;
        }
    }

}

.ag-cell {
    &:has(.productCellRenderer) {
        display: flex;
        align-items: center;
    }
}