ap-setting-company-list-item {
    display: block;
    border-bottom: 1px solid var(--border-color-item-tabs-separator);

    &:first-child {
        border-radius: var(--border-radius-item-tabs) var(--border-radius-item-tabs) 0px 0px;

        .company-title {
            border-radius: var(--border-radius-item-tabs) var(--border-radius-item-tabs) 0px 0px;
        }
    }

    &:last-child {
        border-bottom: 0;
    }

    &.open {
        .company-title {

            color: var(--active-tabs-color);
            background: var(--active-tabs-background);

            &::after {
                transform: scale(1.5) rotate(90deg);
            }
        }

        .company-container {
            height: calc(var(--number-line) * var(--height-item-tabs));
        }

        &:last-child {
            .company-link {
                &:last-child {
                    border-radius: 0px 0px var(--border-radius-item-tabs) var(--border-radius-item-tabs);
                }
            }
        }
    }

    &:not(.open) {
        &:last-child {
            .company-title {
                border-radius: 0px 0px var(--border-radius-item-tabs) var(--border-radius-item-tabs);
            }
        }
    }

    .company-title,
    .company-link {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        cursor: pointer;
        height: var(--height-item-tabs);
        padding: 15px;
    }

    .company-title {
        position: relative;
        transition: border-radius 0.3s;

        &::after {
            content: "›";
            transform: scale(1.5) rotate(0deg);
            font-weight: bold;
            position: absolute;
            right: 15px;
            height: var(--height-item-tabs);
            display: flex;
            align-items: center;
            justify-content: center;
            transform-origin: center center;
            transition: 0.5s transform;
        }

        &:hover {
            color: var(--hover-tabs-color);
            background: var(--hover-tabs-background);
        }
    }

    .company-link {
        background: #F9F9F9;

        &:hover {
            color: var(--hover-tabs-color);
            background: color-mix(in srgb, #F9F9F9 50%, var(--hover-tabs-background) 50%);
        }

        &.active {
            color: var(--active-tabs-color);
            background: color-mix(in srgb, #F9F9F9 50%, var(--active-tabs-background) 50%);
        }
    }

    .company-container {
        height: 0px;
        overflow: hidden;
        transition: height 0.3s;
    }

}