.loading {
    ap-create-order-product-line {
        .text-danger {
            &.cell-loading {
                border-radius: 50px;
            }

            color: transparent !important
        }

        ap-quantity {
            display: none;
        }

        ap-button {
            display: none;
        }

        &.error {
            .price {
                color: transparent;
            }
        }
    }
}

ap-create-order-product-line {

    user-select: none;
    display: grid;
    grid-template-columns: auto 40%;
    grid-template-rows: 65px;
    gap: 10px;

    padding-top: 10px;

    &:hover {
        background-color: #f7faff;
        cursor: pointer;
    }

    &:has(+ ap-create-order-brand-line),
    &:has(+ .title-quote),
    &:last-child {

        .col-right,
        .col-left {
            border-bottom: none;
        }
    }

    &.error {
        .price {
            color: #FF0000;
        }
    }

    &.selected {
        background-color: #eff6ff;
        position: relative;

        &::before {
            content: "‹";
            font-weight: bold;
            position: absolute;
            left: -10px;
            height: 100%;
            display: flex;
            align-items: center;
            transform: scale(2.5);
            top: -2px;
        }
    }

    .col-right {

        display: grid;
        grid-template-columns: 50px repeat(2, 1fr) 100px;
        grid-template-rows: repeat(2, 1fr);

        column-gap: 10px;

        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;

        border-bottom: 1px solid #d4d4d4;

        .image {
            grid-area: 1 / 1 / 3 / 2;
            display: flex;
            align-items: center;
        }

        .reference {
            grid-area: 1 / 2 / 2 / 3;
            font-weight: 700;
            color: #6D6D6D;
            display: flex;
            align-items: center;

            &.cell-loading {
                border-radius: 50px;
            }
        }

        .provider {
            grid-area: 1 / 3 / 2 / 4;
            font-weight: 700;
            color: #6D6D6D;
            display: flex;
            align-items: center;

            &.cell-loading {
                border-radius: 50px;
            }
        }

        .label {
            grid-area: 2 / 2 / 3 / 4;
            color: #818181;
            display: flex;
            align-items: center;

            &.cell-loading {
                border-radius: 50px;
            }
        }

        .price {
            grid-area: 1 / 4 / 3 / 5;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-weight: 700;
        }

    }

    .col-left {

        display: grid;
        grid-template-columns: 200px auto 50px;
        grid-template-rows: repeat(1, 1fr);

        column-gap: 10px;

        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;

        border-bottom: 1px solid #B6B6B6;

        .quantity {
            grid-area: 1 / 1 / 2 / 2;
            display: flex;
            align-items: center;
            gap: 20px;
        }

        .total {
            grid-area: 1 / 2 / 2 / 3;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-weight: bold;

            .price {
                margin-left: 5px;
            }
        }

        .close {
            grid-area: 1 / 3 / 2 / 4;
            display: flex;
            align-items: center;
        }

    }

}