.button-edit-aggrid {

    display: flex;

    border-radius: var(--border-radius, 8px);

    --hover-background: rgba(0, 0, 0, 0.1);
    --hover-color: black;

    ap-button {
        --background: transparent;
        --color: black;

        --active-background: rgba(0, 0, 0, 0.1);
        --active-color: black;
    }

    &:hover {
        background: var(--hover-background);
        color: var(--hover-color);

        .container-buttons {
            width: var(--max-width);
            overflow: hidden;
        }
    }

    .container-buttons {
        width: 0;
        transition: width 0.3s;
        overflow: hidden;
        display: flex;
    }

}