.maintenance {

    gap: 4rem;

    .card-container {
        background-color: white;
        border-radius: 8px;
        padding: 4rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;

        .title {
            font-size: 40px;
            color: #817F88;
            font-weight: bold;
        }

        .text {
            color: #828282;
            font-weight: 500;
        }

        .link-button {
            border: none;
            background: transparent;
            text-decoration: underline;
            color: #4D4D4D;
        }
    }

    .logo-container {
        display: flex;
        align-items: center;
    }
}