.brandCellRenderer {

    display: flex;
    align-items: center;

}

.ag-cell {
    &:has(.brandCellRenderer) {
        display: flex;
        align-items: center;
    }
}