.page-controller {

    &.page-order-provider {

        display: flex;
        gap: 10px;
        padding-right: calc(20px - 10px) !important;
        /*20px  marge de base sur le page-content - gap*/

        .grid-container {
            grid-template-columns: 20% auto 20% 20%;
            grid-template-rows: 30% minmax(0, 1fr);
        }

        #generalInformation {
            grid-area: 1 / 1 / 2 / 2;
        }

        #details {
            grid-area: 1 / 2 / 2 / 3;
        }

        #amount {
            grid-area: 1 / 3 / 2 / 4;
        }

        #amount2 {
            grid-area: 1 / 4 / 2 / 5;
        }

        #fullscreen {
            grid-area: 2 / 1 / 3 / 5;
        }

        #info {
            .page-tabs-content {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;

                >div {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }
        }

    }

}