ap-input-addon {

    --height: calc(1.5em + 0.75rem + 2px);
    --border-radius: 5px;
    --border-height: 1px;
    --border-color: #D4D7DB;
    --background-color: #FFFFFF;

    border-radius: var(--border-radius);
    display: flex;

    line-height: calc(var(--height) - 10px);
    height: var(--height);

    width: 100%;


    input {
        flex-grow: 1;
        width: 100%;

        border-left: var(--border-height) solid var(--border-color);
        border-top: var(--border-height) solid var(--border-color);
        border-right: none;
        border-bottom: var(--border-height) solid var(--border-color);

        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);

        padding: 1px 10px;

        outline: none;
    }


    .suffix {
        height: var(--height);
        background-color: var(--background-color);
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 1px 10px;

        border-left: none;
        border-top: var(--border-height) solid var(--border-color);
        border-right: var(--border-height) solid var(--border-color);
        border-bottom: var(--border-height) solid var(--border-color);

        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
    }

    //TODO: Remplacer par une classe invalid
    &.error, &.invalid {
        --border-color: var(--color-form-invalid);
    }
}