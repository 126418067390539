.page-settings-companies {

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    overflow: auto;

    .company-block {
        &:not(:last-child) {
            margin-bottom: 2rem;
        }

        .company-block-subtitle {
            &:not(:first-child) {
                margin-top: 1rem;
            }

            margin-bottom: 0.25rem;
            font-size: 18px;
            font-weight: 600;
        }

        .company-block-header {
            margin-bottom: 1rem;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #DADADA;
            height: 40px;
            display: flex;
            align-items: center;

            .company-block-title {
                font-size: 22px;
                font-weight: 500;

                #selection-message {
                    color: #7c7c7c;
                }

            }

            .company-block-buttons {
                display: flex;
                gap: 1rem;
            }

        }

        .company-block-body {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .company-block-information {
                display: flex;
                border-bottom: 1px dotted #dadada;
                padding: 0.5rem 0;
            }

            .company-block-label {
                font-weight: 600;
                width: 50%;
            }

            .company-block-value {
                color: #7c7c7c;
                width: 50%;
            }
        }

        #company-logo {
            height: 200px;
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 1rem;
            align-items: center;
            color: #7c7c7c;
            border: 1px dashed #d1d1d1;
            border-radius: 0.5rem;

            img {
                max-width: 100%;
                max-height: 100%;
            }

        }

        #company-address-list,
        #company-bank-list {
            height: 250px;
            width: 100%;
        }

    }

}