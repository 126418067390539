.modal-order-from-quote {
    .step-quote {
        display: grid;
        grid-template-columns: 400px auto;
        grid-gap: 1.5rem;
        gap: 1.5rem;
        height: 100%;

        .form-group {
            margin-bottom: 0;

            label {
                color: #818181 !important;
                font-weight: 500 !important;
            }
        }

        .col-left {
            overflow-x: auto;
            position: relative;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            .page-card {
                box-shadow: none;
                padding: 1rem;
                border-radius: 10px;
                border-bottom: 0;

                .page-card-body[data-readonly-edit] {
                    gap: 0.25rem;
                }
            }

            .page-card-header {
                top: 0;
                z-index: 1;
            }
        }
    }
}