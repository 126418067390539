@import "../upload-file.scss";

ap-files-edit {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;

    .title-files-edit {
        font-weight: bold;
    }
}