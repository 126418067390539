.modal-confirm {

    .modal-dialog {
        width: 500px;
    }

    .modal-content {
        min-height: 230px;
    }

    .modal-header {
        background-color: #F5F4F7;
        color: #696969;
    }

    .modal-body {
        font-size: 14px;
        font-weight: 500;
        background-color: #F5F4F7;
        color: #696969;

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .modal-footer {
        border: none;
        padding: 1rem !important;
    }

}