ap-quotes-options-tab {

    &.content-only {

        .page-tabs-container {
            display: block;
        }

        .page-tabs-title {
            display: none;
        }

    }
}

ap-quotes-options-input {
    &.no-compta {
        ap-select {
            display: none;
        }
    }
}

.page-tabs-quote-options {
    .title-quote-options {
        font-weight: bold;
        margin-bottom: 10px;
    }

    .text-info-quote-options {
        color: #6c757d;
        margin-left: 47px;
    }

    .line {
        margin-top: 10px;
        margin-bottom: 10px;
    }


    .block-input-quote-options {
        display: flex;
        align-items: center;
        gap: 10px;

        label {
            width: 70%;
            margin-bottom: 0;
        }

        ap-input-addon {
            width: initial;
        }

        ap-select {
            width: 50%;
        }
    }

    .quote-options-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}