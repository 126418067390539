.loading {

    .cell-loading {
        background: #eee;
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        border-radius: 5px;
        border: 2px solid white;
        background-size: 200% 100% !important;
        animation: 1.5s shine linear infinite;
        width: 100%;
        color: transparent !important;
        pointer-events: none;
        user-select: none;

        * {
            visibility: hidden;
        }

        ap-select {
            border: none solid white;
        }

        &.form-control:not(#login-pass):not(#input-login) {

            pointer-events: none;
            color: transparent !important;
            resize: none;

            &::-webkit-inner-spin-button,
            &::-webkit-calendar-picker-indicator {
                display: none !important;
            }
        }
    }

    @keyframes shine {
        to {
            background-position-x: -200%;
        }
    }

}

ap-aggrid {
    .cell-loading {
        height: 100%
    }
}