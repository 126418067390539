.container-title-modal-stock {

    flex-grow: 1;

    display: flex;

    >div {
        flex-grow: 1;
    }
}

.container-form-modal-stock {

    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    >div {
        flex-grow: 1;
    }
}

.mobile {
    .container-form-modal-stock {
        flex-direction: column;
    }
}

.mobile {
    .modal-header:has(.container-title-modal-stock) {
        height: 80px;
    }

    .container-title-modal-stock {
        position: absolute;
        top: 7px;

        flex-direction: column;
    }
}