.upload-file {

    position: relative;

    input {
        display: none;
    }

    label {
        border: 1px dashed #D4D7DB;
        border-radius: 5px;

        color: #D4D7DB;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: 20px;

        margin-top: 10px;

        cursor: pointer;

        &:hover {
            border: 1px dashed #a7aaaf;
            color: #a7aaaf;
        }

        &.dragover {
            border: 1px dashed #3475f8;
        }

        div {
            text-align: center;
        }
    }

}