.maintenance {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .content-text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        .title {
            font-size: 25px;
            text-align: center;
        }

        .text {
            text-align: center;
        }

    }

}