#navbar {

    display: grid;
    grid-template-columns: 0 0 auto 1fr auto;
    align-content: center;

    .navbar-brand {

        display: flex;
        align-items: center;
        margin-right: initial !important;

        #navbar-brand-logo {
            margin-left: 2px;
            margin-right: 10px;
            width: 30px;
            height: 30px;
            max-width: 30px;
            max-height: 30px;
        }

        #navbar-brand-name {
            font-size: 22px;
        }
    }

    #navbar-content {
        overflow: hidden;
    }
}