.modal-edit-order-customer {

    .modal-body {
        display: grid;
        grid-template-columns: 300px auto;
        gap: 2rem;

        .form-group {
            margin-bottom: 0;

            label {
                color: #818181 !important;
                font-weight: 500 !important;
            }
        }

        .col-left {
            overflow-x: auto;

            position: relative;

            .page-card-header {
                background: white;
                position: sticky;
                top: 0;
                z-index: 1;
            }
        }
    }

}