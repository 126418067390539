.page-settings-usergroups {

    .content-settings {

        display: flex;
        flex-direction: column;

        .tabs {

            display: flex;
            width: 100%;
            flex-shrink: 1;

            border-bottom: 1px solid #D9D9D9;

            .item-tab {
                font-size: 18px;
                padding: 10px;

                position: relative;
                bottom: -1px;
                cursor: pointer;

                &.active {
                    border-bottom: 2px solid var(--active-tabs-color);
                    color: var(--active-tabs-color);
                }

            }
        }

        .content {
            height: 100%;
            width: 100%;

            display: flex;
            flex-direction: row;
            overflow: hidden;
            padding-top: 10px;
        }
    }

}