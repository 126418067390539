.ag-theme-alpine .ag-cell.ag-cell-renderer-not-finish {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .notFinishCellRenderer {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}