:root {
    --color-form-invalid: #EA5757;
}


.form-item {
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}

body:not(:has(.loading)) {

    .submitted {

        .ap-input,
        .ap-textarea,
        ap-select {
            &:invalid {
                border-color: var(--color-form-invalid);

                &~.invalid-feedback {
                    display: block;
                    color: var(--color-form-invalid);
                }

                &+.suffix {
                    display: block;
                    color: var(--color-form-invalid);
                }
            }

            &:valid {
                +.invalid-feedback {
                    display: none;
                }
            }
        }

        ap-input-addon {

            input {
                &:invalid {
                    --border-color: var(--color-form-invalid);

                    &+.invalid-feedback {
                        display: block;
                        color: var(--color-form-invalid);
                    }

                    &+.suffix {
                        border-color: var(--color-form-invalid);
                    }
                }
            }

            &.invalid {
                &+.invalid-feedback {
                    display: block;
                    color: var(--color-form-invalid);
                }
            }

            &.valid {
                +.invalid-feedback {
                    display: none;
                }
            }

        }

    }

}