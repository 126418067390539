ap-progress {

    display: flex;
    gap: 6px;
    align-items: center;
    height: 100%;

    .progress {
        background: #EBEAEF;
        box-shadow: none;
        justify-content: flex-start;
        border-radius: 100px;
        align-items: center;
        position: relative;
        display: flex;
        height: 8px;
        flex-grow: 1;


        .progress-value {
            border-radius: 100px;
            background: #6867F7;
            height: 100%;
            width: var(--percent);
        }

    }

    &.animate {
        .progress-value {
            width: 0;
            animation: load 3s normal forwards;
        }
    }


    .value {
        display: flex;
        align-items: center;
        justify-content: end;
        width: 5ch;
    }

    @keyframes load {
        0% {
            width: 0;
        }

        100% {
            width: var(--percent);
        }
    }

}