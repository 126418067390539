.page-tabs-card {

    display: grid !important;
    grid-template-columns: 40px auto;
    transition: grid-template-columns 0.1s ease-in-out;
    gap: 0 !important;
    border-radius: 10px;
    background-color: white;
    padding: 0 !important;



    &:has(ap-page-tabs-menu.active) {
        grid-template-columns: 15% auto;
    }

    ap-page-tabs-menu {
        overflow-y: auto;
        overflow-x: hidden;

        background-color: #fafafa;

        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        gap: 10px;

        &:not(.active) {
            ap-page-tabs-menu-item {
                border-radius: 0;
                margin: 0;
            }

            .page-tabs-menu-item-text {
                display: none;
            }

            .page-tabs-menu-item-arrow {
                display: none;
            }

            ap-page-tabs-menu-title {
                ap-icon {
                    &.menu-active-icon {
                        display: none;
                    }
                }
            }
    
        }

        &.active {
            ap-page-tabs-menu-title {
                ap-icon {
                    &.menu-icon {
                        display: none;
                    }
                }
            }
        }

        ap-page-tabs-menu-item {
            height: 40px;
            min-height: 40px;
            max-height: 40px;

            border-radius: 10px;
            margin: 0 10px;

            transition: all 0.1s ease-in-out;
            color: #6e6e6e;
            
            display: flex;
            align-items: center;

            gap: 10px;
            padding: 10px;
            margin-bottom: 10px;

            position: relative;
            cursor: pointer;

            white-space: nowrap;

            &:hover {
                background-color: #77a5ff;
                color: white;
            }

            &.active {
                color: white;
                background-color: #3475f8;

				.page-tabs-menu-item-arrow {                
					display: flex;
					align-items: center;
					margin-left: auto;
				}
            }

            .page-tabs-menu-item-icon {
                font-size: 18px !important;

                display: flex;
                align-items: center;
            }

            .page-tabs-menu-item-text {
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .page-tabs-menu-item-arrow {
				display: none;
			}

            .page-tabs-menu-item-badge {
                position: absolute;
                top: 0;
                left: 25px;
                font-size: 0.75em;
                font-weight: 700;
            }
        }

        ap-page-tabs-menu-title {

            position: sticky;
            top: 0;

            background-color: inherit;
            color: #6e6e6e;

            display: grid;
            align-items: center;
            grid-template-columns: 20px auto;
            font-weight: bold;

            gap: 10px;
            padding: 10px;

            z-index: 1;

            font-size: 1rem;

            cursor: pointer;

            white-space: nowrap;

            ap-icon {
                font-size: 1.3rem !important;
            }
        }

    }

    ap-page-tabs-menu-content {
        height: 100%;
        overflow: hidden;
        padding: 0px 1rem;
    }

}

.page-tabs-container {
    height: 100%;
    display: none;
    grid-template-rows: 47px auto;
    gap: 1rem;
    overflow: hidden;

    &.active {
        display: grid;
    }

    &.no-title {
        grid-template-rows: 0 auto !important;

        .page-tabs-title {
            visibility: hidden;
        }
    }

    .page-tabs-title {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 1.5rem;
        color: black;
        border-bottom: 1px solid #dee2e6;

        .page-tabs-title-right {
            display: flex;
            gap: 10px;
            margin-left: auto;
            font-weight: normal;
        }
    }

    .page-tabs-content {
        width: 100%;
        height: 100%;
        overflow: auto;
        display: flex;

        .page-tabs-content-subtitle {
            font-weight: bold;
        }

    }

}

ap-page-tabs-menu {
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    
}