ap-edit-tags {

    display: block;
    width: 100%;

    &.select-value {

        .select {
            display: flex;
        }

        .content {
            .add {
                display: none;
            }
        }
    }

    .select {
        display: none;

        ap-select {
            width: 100%;
        }
    }

    .content {

        display: flex;
        gap: 5px;
        flex-wrap: wrap;
        margin-bottom: 10px;

        .item {
            display: flex;
            background-color: #252A38;
            border-radius: 22px;
            color: #EAE8EC;
            align-items: center;
            padding-left: 12px;
            height: 22px;
            font-size: 12px;

            ap-button {
                height: 20px;
                width: 20px;
                max-height: 20px;
                max-width: 20px;
            }

            &.add {
                background-color: white;
                padding-right: 10px;
                color: #252A38;
                cursor: pointer;

                &:hover {
                    background-color: #ebedf3;
                }
            }
        }

    }

}