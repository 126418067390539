.mobile {
    ap-company-select {

        #selected-company-name {
            display: none;
        }

        .custom-nav-select {
            color: black;

            &#single-company {
                display: none;
            }
        }

        .dropdown-toggle-arrow {
            display: none;
        }

        .dropdown-menu {
            margin-top: -12px;
        }
    }
}

ap-company-select {

    position: relative;

    .custom-nav-select {
        display: flex;
        align-items: center;
        background: none !important;
        border: none !important;
        color: white;
        border-radius: 8px;

        &#no-company,
        &#single-company {
            display: flex;
            gap: 0.5rem;
            padding: 0 0.9rem;
            height: 42px;
        }

        &#no-company {
            opacity: 0.5;
        }

        &#company-dropdown {

            cursor: pointer;

            &:hover {
                background-color: rgba(255, 255, 255, 0.1) !important;
            }

        }
    }

    .dropdown-toggle {
        display: flex;
        align-items: center;
		padding: 0 0.5rem 0 0.9rem;
        height: 42px;
        
        &::after {
            display: none;
        }

        ap-icon {
            display: flex;
            font-size: 18px;
        }

        .dropdown-toggle-arrow {
            ap-icon {
                font-size: 20px;
            }
        }

        .company-icon {
            font-size: 18px;
        }

        .company-name {
			font-size: 14px;
			font-weight: 500;
			margin-left: 0.5rem;
            margin-right: 0.25rem;
        }
    }

    .dropdown-menu {
        left: auto;
        right: 0;
		top: calc(100% + 0.75rem);
        
        background: #363A45;
        border-radius: 10px;
        padding: 0.5rem;

        .dropdown-item {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            border-radius: 6px;

            color: white;

            &:hover {
                background-color: rgba(255, 255, 255, 0.1) !important;
            }

            &.active {
                background-color: transparent;
            }

        }
    }

    .company-logo {
        img {
            max-width: 32px;
            max-height: 32px;
        }
    }

}