.page-settings-notification {

    .block-notification {

        display: grid;
        grid-template-columns: 200px auto;
        border-bottom: 1px solid #D9D9D9;
        margin-bottom: 20px;
        padding-bottom: 20px;

        .block-title {
            font-weight: bold;
            padding-top: 7px;
        }

        .block-container-items {


            .block-item:not(:last-child) {
                border-bottom: 1px solid #D9D9D9;
                margin-bottom: 20px;
                padding-bottom: 20px;

            }

            .block-item {
                display: grid;
                grid-template-columns: 200px 400px;
                align-items: center;
            }
        }



    }
}