ap-select2-mobile {
    #container-list {
        width: 100%;

        border-radius: 10px;
        padding: 10px;

        border: 2px solid #e1e1e1;
        background: white;

		position: relative;
    	z-index: 9999;

        #list {
            overflow-x: auto;
            max-height: 150px;

            .item {
                &.active {
                    background: #5baaff;
                }

                margin-top: 10px;

                border-radius: 10px;
                padding: 2px;
            }
        }
    }
}