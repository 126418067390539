.modal-view-product {
    .modal-body {
        display: grid;
        grid-template-columns: 220px auto;
        grid-template-rows: 100%;
    }

    &.loading {
        .page-card-label {
            &.cell-loading {
                color: transparent !important;
            }
        }

        ap-page-tabs-menu {
            pointer-events: none;
        }
    }


    .modal-title {
        display: flex;
        align-items: center;
        gap: 4px;
        width: 100%;

        #deprecated-badge {
            background-color: var(--ap-red-100);
            color: var(--ap-red-500);
            border-radius: 25px;
            font-size: 12px;
            padding: 6px 25px;
            font-weight: normal;
            white-space: nowrap;
        }

        #validate-badge {
            background-color: #FFDEBF;
            color: #FF7A00;
            border-radius: 25px;
            font-size: 12px;
            padding: 6px 25px;
            font-weight: normal;
            white-space: nowrap;
        }

        .action {
            margin-left: auto;
        }

        .dropdown-menu {
            border-radius: 5px;
            padding: 5px;
            font-weight: 400;

            ap-button {
                width: 100%;
                max-width: 100%;
                justify-content: flex-start;
            }
        }
    }


    .info-product {
        #image {
            height: 180px;
            width: 100%;
        }
    }

    .weight-value {
        display: flex;
        gap: 0.25rem;
    }

    .page-card {
        border-bottom: none !important;
        gap: 1.5rem;
    }

    .page-card-column {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .info-product {
        .page-card-block-value {
            margin-left: 0.5rem;
        }
    }

    .page-card-block-value {
        flex-direction: column;
        margin-bottom: 10px;

        .page-card-value {
            margin-left: 0 !important;
            text-align: left !important;
        }

        &#sellingPriceBlockValue {
            padding: 0.5rem;
            background-color: #f7f7f7;
            border-radius: 8px;

            margin-left: 0;

            .page-card-value {
                font-weight: bold;
            }

            .page-card-info {
                font-style: italic;
            }
        }
    }

    .page-tabs-container {

        gap: 20px;

        &#details-tab {
            .page-card-body {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
            }
        }


        &#caract-tab {
            .page-card-body {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
            }
        }

        &#stock-tab {
            .page-card-body {
                display: grid;
                grid-template-columns: repeat(2, 1fr);

                #container-stock {
                    overflow: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }
        }
    }

    .page-tabs-content {
        gap: 20px;
    }
}