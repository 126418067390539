/* -------------------------------------------------------------------------- */
/*                                 SEARCH BAR                                 */
/* -------------------------------------------------------------------------- */

ap-search-bar {
    #search-bar {

        display: flex;
        align-items: center;

        background-color: white;
        padding: 0.15rem 0.75rem;

        height: 34px;
        min-width: 220px;

        border-radius: 8px;
        border: 1px solid #DBDDE4;

        .search-icon-container {
            display: flex;
            align-items: center;
            font-size: 20px;
            margin-right: 0.5rem;
            color: #616b8a;
            margin-bottom: 2px;
        }

        #search-input {
            border: none;
            background-color: transparent;
            width: 200px;

            font-size: 16px;

            &::placeholder {
                color: #616B8A !important;
            }

            &:focus-visible,
            &:focus {
                outline: 0;
            }
        }
    }
}