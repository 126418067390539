.modal-order-from-quote {
    .step-order {

        display: flex;
        width: 100%;
        height: 100%;

        .step-order-column {
            display: flex;
            flex-direction: column;
            flex-basis: 100%;

            &#new-order {
                flex-basis: 400px;
                flex-shrink: 0;
                overflow: auto;

                &.step-order-column-disabled {
                    
                    >:not(#customer-container) {
                        opacity: 0.4;
                        pointer-events: none;
                    }
                }

                #customer-container {
                    margin-bottom: 1.5rem;
                }
            }

            .step-order-column-title {
                font-weight: 600;
                margin-bottom: 1.1rem;
            }
            
            .step-order-column-content {
                height: 100%;

                &#customer-container {
                    height: auto;
                }
            }
        }

        .step-order-column-separator {
            margin: 0 2rem;
            flex-basis: 0;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            .step-order-column-separator-line {
                border-right: 1px solid #64676F;
                height: 100%;
            }

            .step-order-column-separator-text {
                margin: 1.25rem 0;
                font-weight: 600;
            }
        }

    }


    .step-modal-form-item {

        display: flex;
        flex-direction: column;
        gap: 0.15rem;
        margin-bottom: 0.5rem;

        .step-modal-form-item-label {
            font-weight: 600;
            font-size: 12px;
            margin-bottom: 0;
            color: #374151;
        }

        &:has(.invalid) {
            .step-modal-form-item-label {
                color: var(--color-form-invalid);
            }
        }
    }

    .step-modal-form-subtitle {
        font-weight: 600;
        margin-bottom: 1.25rem;
        margin-top: 1.75rem;
    }
}
