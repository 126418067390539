ap-edit-categories-readonly {

    border: 1px solid #DFE4EA;
    border-radius: 5px;
    height: 100%;

    display: flex;
    flex-direction: column;

    .categories-title {
        background: #F1F1F1;
        font-size: 1.5rem;
        padding: 0.5rem 1rem;
        font-weight: 600;
    }

    .categories-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 1rem;
        overflow: auto;

        .category2 {
            margin-left: 20px;
        }


        .category3 {
            margin-left: 40px;
        }

        &.no-data-category {
            align-items: center;
            justify-content: center;
            color: #959495;
            height: 100%;
        }
    }
}