:root {
    --ap-select-size: 34px;
    --ap-select-border-color: #D4D7DB;
    --ap-select-border-radius: 5px;
}

.ag-select-editor {
    div {
        width: 100%;

        ap-select {
            .container-selection {
                .selection {
                    border-radius: 0;
                    height: 42px !important;
                }
            }
        }
    }
}

.container-select {
    z-index: 30000;

    position: absolute;

    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    display: none;

    font-size: 12px;

    &:has(.open) {
        display: block;
    }

    .dropdown-select {
        display: none;
        position: absolute;
        background-color: white;
        user-select: none;
        gap: 10px;
        border: 1px solid var(--ap-select-border-color);
        border-top: 0px;
        border-radius: 0 0 5px 5px;

        z-index: 1;

        min-width: 250px;
        padding: 10px;

        &.open {
            display: flex;
            flex-direction: column;

            &.open-reverse {
                flex-direction: column-reverse;
            }

            &.invalid {
                border-color: #dc3545;
            }
        }

        &.dropdown-to-long {
            border-top: 1px solid var(--ap-select-border-color);
            border-top-right-radius: 5px;

            .separator {
                margin: 0;

                border-top: 1px solid white;
                width: 15px;
            }

        }

        &[no-search-bar] {
            .container-search {
                display: none;
            }
        }

        &.no-result-bottom {
            .result-bottom {
                display: none;
            }
        }

        &.no-result-create-item {
            .result-create-item {
                display: none;
            }
        }

        &.no-create-button {
            .create-button {
                display: none;
            }
        }

        .result,
        .result-bottom,
        .result-create-item {
            display: flex;
            flex-direction: column;
            gap: 5px;
            overflow: auto;
            max-height: calc(var(--ap-select-size) * 5);

            .item {
                display: inline-flex;
                align-items: center;

                min-height: var(--ap-select-size);
                min-width: 100%;

                border-radius: 5px;
                padding-left: 5px;
                white-space: nowrap;

                &:not(.disabled) {
                    cursor: pointer;

                    &:hover {
                        background-color: rgba(52, 117, 248, 0.5);
                    }
                }

                &.active {
                    background-color: rgba(52, 117, 248, 0.35);
                }
            }

        }

        .create-button {
            width: 100%;

            ap-button {
                width: 100%;
                max-width: 100%;
            }
        }

        .container-search {


            display: flex;
            height: var(--ap-select-size);

            #search {
                display: block;
                outline: none;

                height: 100%;
                width: 100%;
                height: var(--ap-select-size);

                padding: 0.375rem 0.75rem;

                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;

                border: 1px solid var(--ap-select-border-color);
                border-radius: var(--ap-select-border-radius);
            }

            .container-loader {

                display: none;
                align-content: center;
                justify-content: center;
                align-items: center;

                border: 1px solid var(--ap-select-border-color);
                border-left: 0;
                border-radius: 0 var(--ap-select-border-radius) var(--ap-select-border-radius) 0;

                height: 100%;

                padding: 10px;
                padding-left: calc(var(--ap-select-border-radius) + 8px);

                margin-left: calc(-1 * var(--ap-select-border-radius));

                width: calc(var(--ap-select-size) + var(--ap-select-border-radius));


                &.active {
                    display: flex;
                }

                .loader-input {
                    font-size: 2rem;
                    animation: rotation 1s linear infinite;
                }

                @keyframes rotation {
                    0% {
                        transform: rotate(0deg);
                    }

                    100% {
                        transform: rotate(360deg);
                    }
                }
            }


        }

        .separator {
            position: absolute;

            left: 0;
            top: -1px;

            margin: 0 15px;

            z-index: 2;

            border-top: 1px solid var(--ap-select-border-color);

            width: calc(100% - 15px * 2);
        }

        &.open-reverse {

            border-radius: 5px 5px 0 0;
            border-top: 1px solid var(--ap-select-border-color);
            border-bottom: 0px;

            .separator {
                top: initial;
                bottom: -1px;
            }
        }

    }

}

ap-select {

    position: relative;
    display: flex;
    flex-direction: column;

    cursor: pointer;

    .value {
        display: none;
    }

    &.invalid {

        --ap-select-border-color: var(--color-form-invalid);

        &+.invalid-feedback {
            display: block;
            color: var(--color-form-invalid);
        }
    }

    &:not(.invalid) {
        +.invalid-feedback {
            display: none;
        }
    }

    &.open {

        .container-selection {
            .selection {
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                border-bottom: 0px;
            }
        }
    }

    &.disabled {
        cursor: not-allowed;

        .container-selection {
            pointer-events: none;

            --ap-select-background: #EEEEEE;
        }
    }

    &.invalid {
        .container-selection {
            .selection {
                border-color: #dc3545;
            }
        }
    }

    .container-selection {
        width: 100%;
        position: relative;

        .selection {
            display: block;
            cursor: pointer;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            background: var(--ap-select-background, white);

            padding: var(--ap-select-border-radius) 10px;

            border: 1px solid var(--ap-select-border-color);
            border-radius: var(--ap-select-border-radius);

            line-height: calc(var(--ap-select-size) - 5px * 2);
            height: var(--ap-select-size);
        }
    }

    &:not(.disabled-arrow) {

        .container-selection {

            &::after {
                content: "";
                background-color: var(--ap-select-background, white);
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hldnJvbi1kb3duIg0KICAgIHZpZXdCb3g9IjAgMCAxNiAxNiI+DQogICAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIg0KICAgICAgICBkPSJNMS42NDYgNC42NDZhLjUuNSAwIDAgMSAuNzA4IDBMOCAxMC4yOTNsNS42NDYtNS42NDdhLjUuNSAwIDAgMSAuNzA4LjcwOGwtNiA2YS41LjUgMCAwIDEtLjcwOCAwbC02LTZhLjUuNSAwIDAgMSAwLS43MDh6IiAvPg0KPC9zdmc+);
                background-repeat: no-repeat;
                background-size: 50%;
                background-position: center center;

                border-radius: var(--ap-select-border-radius);
                pointer-events: none;

                position: absolute;
                top: 1px;
                right: 1px;
                height: calc(var(--ap-select-size) - 1px * 2);
                width: calc(30px - 1px * 2);
            }

        }

    }

}