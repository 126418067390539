.loading {
    ap-view-product-provider {
        .content {
            &.cell-loading {
                height: 100px;
            }
        }
    }
}

ap-view-product-provider {

    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 1px 25px 0px rgba(0, 0, 0, 0.14);
    padding: 30px;
    display: block;
    user-select: none;

    .cell-loading {
        border-radius: 50px;
    }

    .header {

        display: grid;
        grid-template-columns: auto 110px;
        grid-template-rows: repeat(3, 25px);

        margin-bottom: 20px;

        .provider {
            grid-area: 1 / 1 / 2 / 2;
            display: flex;

            &.cell-loading {
                border-radius: 50px;
            }
        }

        .isDefault {
            grid-area: 1 / 2 / 2 / 3;
            display: flex;
            justify-content: flex-end;

            &.cell-loading {
                border-radius: 50px;
            }

            .value {

                &.yes {
                    color: #04A701;
                }

                &.no {
                    color: #B50606;
                }
            }

        }

        .reference {
            grid-area: 2 / 1 / 3 / 3;
            display: flex;

            &.cell-loading {
                border-radius: 50px;
            }

        }

        .label {
            grid-area: 3 / 1 / 4 / 3;
            display: flex;

            &.cell-loading {
                border-radius: 50px;
            }

        }
    }

    .content {

        border: 1px solid #EFF2EF;
        background: #F7F7F7;

        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto 30px auto 30px;

        border-radius: 5px;
        column-gap: 10px;

        padding: 20px;

        >div {
            display: flex;
            align-items: center;
        }

        .public_price_text {
            grid-area: 1 / 1 / 2 / 2;
            color: #8E8F8C;
        }

        .public_price_value {
            grid-area: 2 / 1 / 3 / 2;
        }

        .discount_text {
            grid-area: 1 / 2 / 2 / 3;
            color: #8E8F8C;
        }

        .discount_value {
            grid-area: 2 / 2 / 3 / 3;
        }

        .purchase_price_text {
            grid-area: 1 / 3 / 2 / 4;
            color: #8E8F8C;
        }

        .purchase_price_value {
            grid-area: 2 / 3 / 3 / 4;
            font-weight: bold;
        }

        .shipPrice_text {
            grid-area: 1 / 4 / 2 / 5;
            color: #8E8F8C;
        }

        .shipPrice_value {
            grid-area: 2 / 4 / 3 / 5;
        }


        .costPrice_text {
            grid-area: 3 / 1 / 4 / 2;
            color: #8E8F8C;
        }

        .costPrice_value {
            grid-area: 4 / 1 / 5 / 2;
        }

        .marginRate_text {
            grid-area: 3 / 2 / 4 / 3;
            color: #8E8F8C;
        }

        .marginRate_value {
            grid-area: 4 / 2 / 5 / 3;
        }

        .recommendedSellPrice_text {
            grid-area: 3 / 3 / 4 / 5;
            color: #8E8F8C;
        }

        .recommendedSellPrice_value {
            grid-area: 4 / 3 / 5 / 5;
        }

    }

    .text {
        color: #A6A6A6;
        font-weight: 400;
        font-size: 14px;
        white-space: nowrap;
    }

    .value {
        color: #000;
        font-size: 14px;
        font-weight: 400;
    }

}