.page-controller {

    &.page-bill-customer {

        .grid-container {
            grid-template-columns: 20% 20% 20% auto;
            grid-template-rows: 30% minmax(0, 1fr);
        }

        #generalInformation {
            grid-area: 1 / 1 / 2 / 2;
        }

        #detailsBill {
            grid-area: 1 / 2 / 2 / 3;
        }

        #dates {
            grid-area: 1 / 3 / 2 / 4;
        }

        #amount {
            grid-area: 1 / 4 / 2 / 5;

            .page-card-body {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;

                >div {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }

                .col-middle {

                    border-left: 1px solid #dee2e6;
                    border-right: 1px solid #dee2e6;

                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }

        #fullscreen {
            grid-area: 2 / 1 / 3 / 5;
        }


        #info {
            .page-tabs-content {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;

                >div {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }
        }

    }

}