.page-controller {

    &.page-quote {

        display: flex;
        gap: 10px;
        padding-right: calc(20px - 10px) !important;
        /*20px  marge de base sur le page-content - gap*/

        .grid-container {
            grid-template-columns: 20% auto 20%;
            grid-template-rows: 30% minmax(0, 1fr);
        }

        #generalInformation {
            grid-area: 1 / 1 / 2 / 2;
        }

        #businessQuote {
            grid-area: 1 / 2 / 2 / 3;
        }

        #details {
            grid-area: 1 / 3 / 2 / 4;
        }

        #fullscreen {
            grid-area: 2 / 1 / 3 / 4;
        }


        #info {
            .page-tabs-content {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;

                >div {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    #container_relunch {
                        opacity: 1;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        &.disabled {
                            opacity: 0.3;
                        }
                    }
                }
            }
        }

        #quotes-options {
            .page-tabs-content {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;

                >div {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }

                #price-list {
                    .page-card-block-value {
                        .page-card-value {
                            margin-left: initial;
                        }

                        #compta {
                            display: flex;
                        }
                    }

                    .no-compta {
                        #compta {
                            display: none !important;
                        }
                    }
                }
            }
        }

    }

}