ap-billing-request-view-group-quote {
    height: 100%;
    width: 100%;
    display: flex;

    padding: 2rem 0 2rem 2rem;

    &.value {
        .container-no-value {
            display: none;
        }

        .container-value {
            display: flex;
        }
    }

    .container-no-value {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        height: 100%;
        width: 100%;

        background-color: #F5F9FF;
        border-radius: 5px;
        color: #647795;

        font-weight: 700;
        font-size: 30px;
    }

    .container-value {
        display: none;

        overflow: auto;

        flex-direction: column;
        border: 1px solid #eaeaea;
        border-radius: 5px;

        padding: 15px;

        height: 100%;
        width: 100%;

        .title-name {
            font-weight: bold;
        }

        .name {
            white-space: pre-wrap;
        }

        .category {
            color: #959595;
        }

        .sub-category {
            color: #bababa;
        }

        .detail {
            display: grid;
            gap: 5px;

            grid-template-columns: repeat(2, auto);
            grid-template-rows: minmax(20px, auto) auto;

            .reference {
                grid-area: 1 / 1 / 2 / 2;
                font-weight: bold;
            }

            .price {
                grid-area: 1 / 2 / 2 / 3;
                font-weight: bold;
                display: flex;
                justify-content: flex-end;
            }

            .label {
                grid-area: 2 / 1 / 3 / 3;
            }
        }

        .separator {
            margin-top: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #eaeaea;
        }
    }
}