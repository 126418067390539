.modal-addTVA {

    .modal-body {
        height: 520px;
    }

    form {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: 110px 1fr;
        grid-template-rows: repeat(auto, auto);
    }

    .item-code {
        grid-area: 1 / 1 / 2 / 2;
    }

    .item-label {
        grid-area: 1 / 2 / 2 / 3;
    }

    .item-rate {
        grid-area: 2 / 1 / 3 / 2;
    }

    .item-type {
        grid-area: 2 / 2 / 3 / 3;
    }

    .item-accountingAccount {
        grid-area: 3 / 1 / 4 / 3;
    }

    .item-specialMention {
        grid-area: 4 / 1 / 5 / 3;
        display: flex;
        flex-direction: column;

        .ap-textarea {
            flex-grow: 1;
            height: 100px;
            min-height: 50px;
            max-height: 150px;
        }
    }
}