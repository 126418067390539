ap-lost-connexion {

    display: flex;
    align-items: center;

    ap-icon {
        display: inline-flex;
        height: 1em;
        color: var(--ap-red);
        font-size: 1.5em;
        margin-right: .25rem;
        margin-left: .25rem;
        padding-right: .5rem;
    }
}