ap-switch {

    --background-not-active: #ccc;
    --color-not-active: #ccc;
    --background-active: #3475F8;
    --color-active: #ccc;

    --background-circle: white;

    --size: 16px;

    display: flex;

    .ap-switch {
        position: relative;
        display: inline-block;
        width: calc(var(--size) * 2 + 5px);
        height: calc(var(--size) * 1.3);
        margin-bottom: 0;

        input {
            display: none;
        }

        .slider {
            display: flex;
            align-items: center;
            padding: 2px;
            cursor: pointer;
            background-color: var(--background-not-active);
            transition: .4s;

            &.round {
                border-radius: calc(var(--size) * 1.3);

                &:before {
                    border-radius: 50%;
                }
            }

            &:before {
                content: "";
                height: var(--size);
                width: var(--size);
                background-color: var(--background-circle);
                transition: .4s;
            }

        }

        input {
            &:checked+.slider {
                background-color: var(--background-active);

                &:before {
                    transform: translateX(var(--size));
                }
            }

            &:focus+.slider {
                box-shadow: 0 0 1px #2196F3;
            }
        }
    }

    +label {
        margin-left: 10px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }
}

.container-ap-switch {
    display: flex;
}