.ap-input,
.ap-textarea {

    --height: calc(1.5em + 0.75rem + 2px);
    --border-radius: 5px;
    --border-color: #D4D7DB;

    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);

    line-height: calc(var(--height) - 10px);

    padding-left: 10px;
    padding-right: 10px;

    width: 100%;

    outline: none;
}

.ap-input {
    height: var(--height);
}

.ap-textarea {
    padding: 10px;
}