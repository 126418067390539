.modal-product {
    .step-stock {

        display: grid;
        grid-template-columns: 300px auto;
        gap: 30px;
        height: 100%;
        width: 100%;

        .col-stock {
            display: flex;
            flex-direction: column;
            gap: 10px;

            background-color: #FFFFFF;
            border-radius: 10px;
            box-shadow: rgba(0,0,0,.05) 0 0 25px 0;
            padding: 1.5rem;

            .container-enableAlarm {
                display: flex;
                flex-direction: row;
                justify-content: space-between
            }
        }

        .col-menu {
            display: flex;
            flex-direction: row;
            overflow: hidden;

            height: 100%;
            width: 100%;

            background-color: #FFFFFF;
            border-radius: 10px;
            box-shadow: rgba(0,0,0,.05) 0 0 25px 0;
            padding: 1.5rem;

            .list {
                background-color: #FAFAFA;
                border-radius: 5px 0 0 5px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 10px;
                overflow: hidden;

                min-width: 250px;
                max-width: 350px;

                flex-shrink: 1;
                flex-grow: 0;

                ap-button {
                    width: 100%;
                    max-width: inherit;
                }

                .list-content {
                    overflow: auto;
                    flex-grow: 1;
                }

                .list-item {
                    padding: 10px;
                    cursor: pointer;
                    position: relative;

                    &.active {
                        background-color: #ECECEC;
                        border-radius: 5px;

                        &::after {
                            top: 9px;
                            right: 10px;
                            position: absolute;
                            font-weight: bold;
                            content: "›";
                            transform: scale(1.5);
                        }
                    }
                }
            }

            .form-list {

                display: flex;
                flex-direction: column;
                flex-grow: 1;

                .form-list-content {
                    display: flex;
                    flex-direction: column;
                    gap: 1.5rem;
                    padding: 35px;
                    border-radius: 0 5px 5px 0;
                    position: relative;

                    .close {
                        position: absolute;
                        right: 7px;
                        top: 7px;
                    }

                    .second-line {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 10px;
                    }

                    .custom-alarm {
                        .enable-custom-alarm {
                            display: flex;
                            justify-content: space-between;
                            max-height: 1.25rem;
                        }
                    }

                }

                .form-list-footer {
                    flex-shrink: 1;
                    padding: 10px 0px;
                    margin: 0px 35px;
                    border-top: 1px solid #DFE4EA;
                }

            }
        }

        .step-modal-form-item {
    
            display: flex;
            flex-direction: column;
            gap: 0.35rem;
            margin-bottom: 1rem;
    
            .step-modal-form-item-label {
                font-weight: 600;
                font-size: 12px;
                margin-bottom: 0;
                color: #374151;
            }
        }

        #delete-stock {
            font-size: 12px;
            font-weight: 500;
        }
    }
}