.modal {

    .modal-dialog-scrollable {
        .modal-content {
            overflow: visible !important;
        }
    }

    .modal-content {
        border: none !important;
        box-shadow: 0 8px 9px 0px rgba(0, 0, 0, 0.2);

        .modal-body {
            overflow: auto;
        }

        .modal-footer {

            background-color: #F5F4F7;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            padding-left: 2rem;
            padding-right: 2rem;

            .require-fields {
                font-size: 10px;
                margin-right: auto;
                margin-bottom: auto;

                color: #878787;
            }
        }
    }

    &:has(.modal-fullscreen-bottom) {

        top: inherit;
        bottom: calc(100% * -1);
        -webkit-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
        -moz-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
        -o-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
        transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
        

        &.show {
            bottom: 0;
        }

        &.fade {

            display: block;

            .modal-dialog {
                transform: none
            }
        }

        &.fade:not(.show) {
            opacity: 1;
        }

    }

    &:not(:has(.modal-fullscreen-bottom)) {

        .modal-header {
            position: relative;

            .close {
                position: absolute;
                right: -8px;
                top: -8px;
                background: white;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                opacity: 1;
                border: 1px solid #dedede;
                color: #b5b5b5;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 2rem;
                padding: 0;
                margin: 0;

                &:hover {
                    color: #a0a0a0 !important;
                }
            }
        }

    }

    .modal-fullscreen {
        max-width: 90%;
        height: 90%;

        .modal-content {
            height: 100%
        }
    }

    .modal-fullscreen-bottom {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 0;
        max-width: inherit;

        width: calc(100% - var(--ap-aside-width));
        height: calc(100% - var(--ap-navbar-height));
        display: flex;
        align-items: center;

        .modal-content {
            height: 100%;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .modal-header {
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
        }

        .modal-footer {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border: none;
            background: white;
        }
    }

}

.modal-title {
    .modal-title-addon {
        color: #818181;
    }
}

/*
Modificiation du backdrop de bootstrap
*/
.modal-backdrop {
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.20) 100%);

    &.show {
        opacity: 1;
    }

    &.fade {
        transition: opacity 0.15s linear;
    }
}

/**
Ombre pour indiquer le scroll de la modal
*/

.modal-scroll-top {
    .modal-header {
        z-index: 1;
        position: relative;

        &::after {
            pointer-events: none;
            content: " ";
            width: 100%;
            top: 85px;
            left: 0;
            height: 40px;
            position: absolute;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0) 100%);
        }
    }
}

.modal-scroll-bottom {
    .modal-footer {
        z-index: 1;
        position: relative;

        &::before {
            pointer-events: none;
            content: " ";
            width: 100%;
            top: -40px;
            left: 0;
            height: 40px;
            position: absolute;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0) 100%);
        }
    }
}

/*
Form dans les modal
*/
.modal {

    .page-card {
        border-radius: 0px;
        box-shadow: none;
        border-bottom: 1px solid #C2C2C2;

        .page-card-label {
            color: #818181 !important;
            font-weight: 500 !important;
        }
    }

    &:not(.loading) {
        .page-card-value {
            color: black !important;
            margin-left: auto;
            text-align: right;
        }
    }
}