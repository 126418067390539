ap-step-modal-nav {

    display: flex;
    gap: 10px;

    --step-custom-element-primary-color: #FF7A00;
    --step-custom-element-active-invalid-color: #ff0000;
    --step-custom-element-invalid-color: #ffa3a3;

    .step {
        height: 12px;
        width: 12px;
        border-radius: 12px;
        background: #D9D9D9;
        cursor: pointer;

        transition: background 0.3s, width 0.3s;

        &.active {
            width: 36px;
            background: var(--step-custom-element-primary-color);
        }

        &.invalid.active {
            background: var(--step-custom-element-active-invalid-color);
        }

        &.invalid {
            background: var(--step-custom-element-invalid-color);
        }
    }

}