ap-messages {
    color: rgb(79, 243, 79);
    font-weight: 700;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;

    .content {
        animation: defilement-rtl 30s infinite linear;
        white-space: nowrap;
        padding-left: 100%;
    }

    @keyframes defilement-rtl {
        0% {
            transform: translate3d(0, 0, 0);
        }

        100% {
            transform: translate3d(-100%, 0, 0);
        }
    }
}