@import './tabs.scss';
@import './card.scss';
@import './button.scss';


.page-controller {

    .line {
        border-bottom: 1px solid #dee2e6;
    }
}