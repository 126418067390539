.tooltip {
    user-select: none;

    white-space: nowrap;

    .arrow {
        display: none;
    }

    &.show {
        opacity: 1;
    }

    .tooltip-inner {
        background-color: rgb(73, 73, 73);

        border-radius: 4px;
        opacity: 1;

        font-family: 'Roboto';
        font-size: 12px;
        font-weight: 500;

        padding: 0.25rem 0.65rem;
    }

    &.tooltip-break-spaces {
        white-space: break-spaces;
    }
}

/** On surcharge le z-index pour qu'il soit au-dessus des modales du core */
[data-tippy-root] {
    z-index: 20000 !important;
}