body {
    &:has(ap-aggrid) {
        &:has(.confirmation-popover) {
            pointer-events: none;
        }

        .confirmation-popover {
            pointer-events: initial;
        }
    }
}


ap-aggrid {
    display: block;
    height: 100%;
    width: 100%;

    #grid {
        height: 100%;
        width: 100%;
    }

    &.dashboard-grid {
        .ag-cell {
            display: flex;
            align-items: center;

            span {
                width: 100%;
            }
        }

        /* -------------------------------------------------------------------------- */
        /*       Pour avoir le style Gmail avec les boutons d'actions au survol       */
        /* -------------------------------------------------------------------------- */

        .ag-theme-alpine {
            --ag-row-hover-color: hsl(207, 90%, 94%);
            --ag-selected-row-background-color: hsl(207, 87%, 86%);
        }

        .ag-pinned-right-floating-bottom,
        .ag-pinned-right-header,
        .ag-horizontal-right-spacer {
            width: 0 !important;
            min-width: 0 !important;
            border: 0 !important;
        }

        .ag-center-cols-container {
            .ag-row {
                padding-right: 100vw;
            }
        }

        .ag-pinned-right-cols-container {
            position: absolute !important;
            right: 0;
            pointer-events: none;

            * {
                pointer-events: initial;
            }

            .ag-row {

                background-color: transparent !important;

                &:not(.ag-row-hover):not(.ag-row-focus) {
                    width: 0 !important;
                    padding: 0 !important;

                    .ag-cell {
                        width: 0 !important;
                        padding: 0 !important;
                    }
                }

                &::before {
                    background-color: transparent !important;
                }

                .ag-cell {
                    left: initial !important;
                    right: 0 !important;
                    width: 120% !important;
                    display: flex;
                    justify-content: flex-end;
                    border: none !important;
                    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 8%, var(--ag-row-hover-color) 40%);
                }
            }
        }

        /* -------------------------------------------------------------------------- */

    }

    &:not(.dashboard-grid) {
        .ag-cell {
            display: flex;
            align-items: flex-start;
        }
    }

    .ag-text-number {
        justify-content: flex-end !important;

        span {
            text-align: right;
        }
    }

    .container-action-aggrid {

        display: flex;
        align-items: center;
        height: 100%;

        .btn-action-aggrid {
            display: flex;

            padding: 5px;

            &.text-white {
                --color: white;
            }

            &.btn-switch {
                &.on {
                    --background: transparent;
                    --color: #43a047;

                    --hover-background: rgba(43, 102, 46, 0.1);
                    --hover-color: #43a047;

                    --active-background: rgba(43, 102, 46, 0.1);
                    --active-color: #43a047;
                }

                &.off {
                    --background: transparent;
                    --color: #d32f2f;

                    --hover-background: rgba(192, 28, 28, 0.1);
                    --hover-color: #d32f2f;

                    --active-background: rgba(192, 28, 28, 0.1);
                    --active-color: #d32f2f;
                }
            }
        }
    }

    .container-icons-aggrid {

        display: flex;
        align-items: center;
        height: 100%;
        gap: 10px;

        .icon-state {
            height: 30px;
            min-height: 30px;
            width: 30px;
            min-width: 30px;
            border-radius: 50%;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--bg-color);
            color: var(--color);

            &.disabled {
                opacity: 0.3;
                background-color: var(--ap-grey-50);
                color: var(--ap-grey-500);
            }

        }

    }

    .icon-aggrid {
        font-size: 18px;
    }

    /*
        Surcharge aggrid
    */
    .ag-pinned-right-header {
        border-left: 0 !important;
    }

    .ag-pinned-right-cols-container,
    .ag-pinned-left-cols-container,
    .ag-pinned-center-cols-container {
        .ag-row {
            border-right: 0 !important;
            border-left: 0 !important;
        }

        .ag-cell {
            border-right: 0 !important;
            border-left: 0 !important;
        }
    }

    .ag-header-row {
        &::after {
            background-color: transparent !important;
        }
    }

    &.invalid {
        .ag-root-wrapper {
            border-color: #dc3545;
            color: #dc3545;
        }

        .ag-header {
            border-bottom-color: #dc3545;
        }
    }

    .cell-invalid {
        background-color: #ff7784;
    }
}