ap-checkbox {

    --border-radius: 5px;
    --border-color: #D4D7DB;

    --size: 17px;

    --color: #959495;
    --color-disabled: #959495;
    --background-color: transparent;

    cursor: pointer;

    display: flex;
    align-items: center;
    white-space: nowrap;

    label {
        font-size: 14px;
        margin-bottom: 0px;
        margin-left: 0.5rem;
        pointer-events: none;
    }

    input[type="checkbox"] {
        background-color: var(--background-color);

        min-width: var(--size);
        min-height: var(--size);

        border: 1px solid var(--border-color);
        border-radius: var(--border-radius);

        transform: translateY(-0.075em);

        display: grid;
        align-content: center;
        justify-content: center;

        outline: none;
        appearance: none;

        pointer-events: none;

        transition: 120ms background-color ease-in-out;

        &:checked {
            background-color: var(--background-color-checked, --background-color);
        }

        &::before {
            content: "";
            width: calc(var(--size) - 7px);
            height: calc(var(--size) - 7px);
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            transform: scale(0);
            transform-origin: bottom left;
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--color);
            pointer-events: none;
        }

        &:checked::before {
            transform: scale(1);
        }

        &:disabled,
        &[disabled] {
            color: var(--color-disabled);
            cursor: not-allowed;

            &+label {
                color: var(--color-disabled);
                cursor: not-allowed;
            }
        }
    }
}