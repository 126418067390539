ap-select-address {
    

    #custom-select-buttons-container {
        display: flex;
        border: 1px solid #dee2e6;
        border-radius: 4px;
        background-color: white;
        margin-left: 0.25rem;
        padding: 0;

        height: calc(1.5em + 0.75rem + 2px);
        font-size: 14px;

        button {
            height: 100%;
            ap-icon {
                font-size: 16px;
                width: 20px;

                &[name="add/fill"] {
                    font-size: 20px;
                }
            }

            padding-left: 0.5rem;
            padding-right: 0.5rem;

            border-radius: 0 !important;

            &:first-child {
                border-top-left-radius: 4px !important;
                border-bottom-left-radius: 4px !important;
            }

            &:last-child {
                border-top-right-radius: 4px !important;
                border-bottom-right-radius: 4px !important;
            }

            &:not(:last-child) {
                border-right: 1px solid #eaedf0 !important;
            }
        }
    }
}