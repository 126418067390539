.modal-addCompte {

    .modal-body {
        height: 400px
    }
    
    form {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, auto);
    }

    .item-code {
        grid-area: 1 / 1 / 2 / 4;
    }

    .item-label {
        grid-area: 2 / 1 / 3 / 4;
    }

    .item-type {
        grid-area: 3 / 1 / 4 / 3;
    }

    .item-usage {
        grid-area: 4 / 1 / 5 / 4;
        p {
            font-weight: 500;
        }
        .checkbox-group {
            display: flex;
            gap: 1rem;
        }
    }
}