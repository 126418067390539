ap-edit-product-provider-readonly {

    border: 1px solid #DFE4EA;
    border-radius: 5px;

    display: flex;
    flex-direction: column;

    .categories-title {
        background: #F1F1F1;
        font-size: 1.5rem;
        padding: 0.5rem 1rem;
        font-weight: 600;
    }

    .categories-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 1rem;
        overflow: auto;

        .category2 {
            margin-left: 20px;
        }


        .category3 {
            margin-left: 40px;
        }

        &.no-data-category {
            align-items: center;
            justify-content: center;
            color: #959495;
            height: 100%;
        }
    }

    &.active {

        .provider-title {
            #icon-active {
                transform: rotate(0);
            }
        }
        .provider-content {
            display: grid;
        }
    }


    .provider-title {
        background: #F1F1F1;
        font-size: 1.5rem;
        padding: 0.5rem 1rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        #icon-active {
            margin-left: auto;
            transform: rotate(180deg);
            transition: transform 0.3s;
        }

    }

    .provider-content {
        display: none;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        padding: 10px;

        .page-card {
            border-bottom: none !important;
            
            &#price {
                .page-card-body {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: auto 100px;

                    border-radius: 5px;

                    padding: 1.5rem;
                    background: #F7F7F7;
                }

                .price-grid-cell {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }
        }

       

        .page-card-block-value {
            &#date {
                margin-top: auto;
                flex-direction: row;

                .page-card-label,
                .page-card-value {
                    color: #ababab !important;
                }
            }
        }
    }
}