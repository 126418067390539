.modal-product {

    .step-detail {

        height: 100%;
        width: 100%;

        display: flex;

        gap: 1.5rem;

        overflow: auto;
        
        
        /* -------------------------------------------------------------------------- */
        /*                       Structure globale de la modale                       */
        /* -------------------------------------------------------------------------- */

        .step-modal-left {
            width: 100%;
            height: 100%;

        }

        .step-modal-center {
            width: 100%;
            height: 100%;
        }

        .step-modal-right {
            width: 100%;
            height: 100%;
        }

        .step-modal-card {
            background-color: #FFFFFF;
            border-radius: 10px;
            box-shadow: rgba(0,0,0,.05) 0 0 25px 0;
            padding: 1.5rem;
            width: 100%;

            display: flex;
            flex-direction: column;
        }
        
        .step-modal-form-item {

            display: flex;
            flex-direction: column;
            gap: 0.35rem;
            margin-bottom: 1rem;

            &.invalid {
                .step-modal-form-item-label {
                    color: var(--color-form-invalid);
                }

                input {
                    border-color: var(--color-form-invalid);
                }
            }

            .step-modal-form-item-label {
                font-weight: 600;
                font-size: 12px;
                margin-bottom: 0;
                color: #374151;
            }
        }

        ap-checkbox {
            label {
                font-size: 12px !important;
            }
        }

        /* -------------------------------------------------------------------------- */
        /*                                Partie gauche                               */
        /* -------------------------------------------------------------------------- */
        
        .step-modal-left {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .step-modal-card {
                height: 100%;
                overflow: auto;
            }
        }

        #internalCode-form-item {
            .group {
                display: grid;
                grid-template-columns: auto 82px;
                grid-template-rows: repeat(2, auto);
                gap: 10px;
            }
        }

        #generate-internalCode {
            font-size: 12px;
            font-weight: 500;
        }


        /* -------------------------------------------------------------------------- */
        /*                               Partie centrale                              */
        /* -------------------------------------------------------------------------- */

        
        .step-modal-center {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            
            .step-modal-card {
                height: 100%;

                &#description-card {
                    .step-modal-form-item {
                        height: 100%;

                        textarea {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}