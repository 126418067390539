.modal-addUnit {

    form {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: 110px 1fr;
        grid-template-rows: repeat(2, auto);
    }

    .item-unit {
        grid-area: 1 / 1 / 2 / 2;
    }

    .item-label {
        grid-area: 1 / 2 / 2 / 3;
    }

    .item-usage {
        grid-area: 2 / 1 / 3 / 3;
    }
}