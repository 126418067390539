.tabs-container {

    align-items: end;
    overflow: visible;

    .btn-tab {
        border-radius: 0;

        box-shadow: none;
        background-color: transparent;
        color: white;
        position: relative;
        margin-left: -15px;

        transition: none;

        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        &:not(.tab-active) {

            &:active,
            &:hover {
                background-color: rgb(241 245 248 / 22%) !important;
                box-shadow: none !important;

                &::before,
                &::after {
                    opacity: 0.2;
                }
            }
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            height: 100%;
            width: 25px;
            top: 0px;
            pointer-events: none;
            opacity: 0;
            box-shadow: 0px 15px 0 0 var(--AppColorBackground);
        }

        &::before {
            left: -25px;
            border-bottom-right-radius: 15px;
        }

        &::after {
            right: -25px;
            border-bottom-left-radius: 15px;
        }

        &.btn-tab:has(+ .tab-active) {
            border-top-right-radius: 0px;
        }

        &.tab-active {
            background-color: var(--AppColorBackground);
            color: black;

            z-index: 900;

            &:active,
            &:hover {
                opacity: 1;
                box-shadow: none !important;
            }

            &::before,
            &::after {
                opacity: 1;
            }

            &+.btn-tab {
                border-top-left-radius: 0px;
            }
        }
    }

}