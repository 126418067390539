ap-navbar-user {
	position: relative;

	#user-button {
		padding: 0 0.5rem 0 0.9rem;
		height: 42px;

		#user-name {
			font-size: 14px;
			font-weight: 500;
			margin-left: 0.5rem;
            margin-right: 0.25rem;
		}

		ap-icon {
			font-size: 20px;
		}
	}

	.dropdown-menu {
        left: auto;
        right: 0;
		top: calc(100% + 0.75rem);

        background: #363A45;
        border-radius: 10px;
        padding: 0.5rem;

		.dropdown-item {
			display: flex;
			justify-content: center;

            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
			
            border-radius: 6px;
			
            color: white;
			font-weight: 500;

            &:hover {
                background-color: rgba(255, 255, 255, 0.1) !important;
            }

            &.active {
                background-color: rgba(255, 255, 255, 0.1) !important;
                text-decoration: underline;
            }

			&.dropdown-item-red {
				color: #f05050;
				font-weight: 600;
			}
		}
	}
}