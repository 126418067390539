.ag-cell-scrollable {
    .textareaCellRenderer {
        display: block;
        height: 100%;
        width: 100%;
        overflow-x: auto;
    }
}

.textareaCellRenderer {
    white-space: pre-line;
    line-height: 17px;
    padding: 10px 0;
}