:root {
	--ap-aside-background: #363a45;
	--ap-navbar-background: #363a45;
	--ap-page-title-background: #3475f8;
	--ap-cover-page-aside-background: #3475f8;
	--ap-cover-page-background: #3475f8;
	--AppColorBackground: #F2F1F7;
}

:root .is-admin {
	--ap-aside-background: #3f51b5;
	--ap-navbar-background: #3f51b5;
	--ap-page-title-background: #363a45;
	--ap-cover-page-aside-background: #363a45;
	--ap-cover-page-background: #363a45;
}

#login-btn {
	--background: #3475f8;
	--color: white;
	--border-color: white;
	--hover-background: #1762fa;
	--hover-color: white;
	--active-background: #1762fa;
	--active-color: white;
}

#login-form {
	height: 100%;
	justify-content: center;
}

.dropdown-item {
	display: flex;
	align-items: center;
	cursor: pointer;

	ap-icon {
		margin-right: 0.5rem;
	}
}

.modal {
	&.modal-static {
		.modal-dialog {
			transform: none !important;
		}
	}
}

button {
	outline: none !important;
}

.btn-xs {
	--size: 2.285em !important;
	font-size: 0.8em !important;
}

.dropdown-item {
	&.disabled {
		color: #6c757d !important;
	}
}

#app-content {

	background-color: var(--AppColorBackground);

	&:not(:has(.cover-page)) {
		position: relative;
	}

	#page-title {
		.title {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}


	overflow: hidden;
}

#app {
	&.no-navbar {
		padding-top: 0;
	}
}

.navbar-bottom {
	background: white;
}

.slimscroll-container {
	overflow: auto;
}