.btn-page-controller-id {

    display: flex;
    padding: 5px;

    ap-icon {
        font-size: 18px;
    }

    &.btn-delete {
        --background: transparent;
        --color: black;

        --hover-color: #F44336;
        --hover-background: transparent;

        --active-color: #F44336;
        --active-background: transparent;
    }

    &.btn-edit {
        --background: transparent;
        --color: black;

        --hover-color: #3475F8;
        --hover-background: transparent;

        --active-color: #3475F8;
        --active-background: transparent;
    }

    &.btn-duplicate {
        --background: transparent;
        --color: black;

        --hover-color: #9c27b0;
        --hover-background: transparent;

        --active-color: #9c27b0;
        --active-background: transparent;
    }
}