.ag-watermark {
	display: none !important;
}

.ag-input-field-input {
	color: rgb(0, 0, 0) !important;
}

.ag-theme-alpine,
.ag-theme-alpine {
	.ag-row-drag {
		margin-right: 0px !important;
	}
}

.ag-theme-custom-text-center .ag-header-cell-label {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ag-input-text-wrapper {
	display: flex;
	align-items: center;
}

#fileIndicator {
	position: relative;
	margin: 0;
	padding-right: 6px;

	.custom-badge {
		top: 20px !important;
	}
}

.title-context-menu {
	font-weight: bold !important;
	opacity: 1 !important;
	color: #343a40 !important;
}

.ag-floating-filter-input:read-only {
	background-color: transparent;
}

// Style pour les SelectFitler
.ag-input-text-wrapper,
.ag-input-text-wrapper.ag-date-filter {
	.form-control {
		height: 24px;
		border-radius: 3px !important;
		padding: 0;
		border-color: var(--ag-border-color, #babfc7) !important;
	}

	a {
		position: relative;
		right: 15px;
		cursor: pointer;
		top: 2px;
	}
}

.ag-menu {
	border-radius: 6px !important;
	background-color: white;

	--ag-row-hover-color: #ebebeb !important;
}

.ag-theme-alpine .ag-cell.ag-cell-inline-editing {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
	padding-left: 10px !important;
	padding-right: 10px !important;
	/*par defaut 17px*/
}

.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
	padding-left: 11px !important;
	padding-right: 11px !important;
	/*par defaut 18px*/
}

.ag-root-wrapper {
	border-radius: 10px;
}

.ag-center-cols-clipper {
	.ag-row-focus {
		box-shadow: inset 4px 0px 0px 0px #000000, inset -4px 0px 0px 0px #000000;
	}
}

.ag-pinned-right-cols-container {
	.ag-row-focus {
		box-shadow: inset -4px 0px 0px 0px #000000;
	}
}

.ag-pinned-left-cols-container {
	.ag-row-focus {
		box-shadow: inset 4px 0px 0px 0px #000000;
	}
}

.ag-theme-alpine .ag-cell.ag-cell-error {
	color: #EA5757;
	border-color: #EA5757;
	text-align: right;
	background-image: url('data:image/svg+xml,%3C%3Fxml version="1.0" encoding="UTF-8" standalone="no"%3F%3E%3Csvg viewBox="0 0 24 24" version="1.1" id="svg6" sodipodi:docname="error-warning-line.svg" inkscape:version="1.2.2 (1:1.2.2+202305151914+b0a8486541)" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"%3E%3Cdefs id="defs10" /%3E%3Csodipodi:namedview id="namedview8" pagecolor="%23ffffff" bordercolor="%23000000" borderopacity="0.25" inkscape:showpageshadow="2" inkscape:pageopacity="0.0" inkscape:pagecheckerboard="0" inkscape:deskcolor="%23d1d1d1" showgrid="false" inkscape:zoom="32.833333" inkscape:cx="12" inkscape:cy="12" inkscape:window-width="1920" inkscape:window-height="947" inkscape:window-x="0" inkscape:window-y="0" inkscape:window-maximized="1" inkscape:current-layer="svg6" /%3E%3Cpath fill="none" d="M0 0h24v24H0z" id="path2" /%3E%3Cpath d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z" id="path4" style="fill:%23ea5757;fill-opacity:1" /%3E%3C/svg%3E%0A');
	background-repeat: no-repeat;
	background-position: right calc(.375em + .1875rem) center;
	background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.ag-theme-alpine .ag-menu-option-icon {
	display: flex;
	align-items: center;
	width: 100%;

	ap-icon {
		height: 16px;
		width: 16px;
	}
}