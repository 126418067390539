.modal-step-modal {

    &[theme="edit"] {
        --step-modal-primary-color: #FF7A00;
        --step-modal-secondary-color: rgba(255, 123, 0, 0.10);
    }

    &[theme="add"] {
        --step-modal-primary-color: #3475F8;
        --step-modal-secondary-color: rgba(52, 117, 248, 0.10);
    }

    .modal-body {

        padding-left: 0;
        padding-right: 0;
        position: relative;
        overflow-x: hidden !important;
        overflow-y: auto !important;

        .step {

            position: absolute;
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            transition: transform 0.3s;

            &.step-prev {
                transform: translateX(-100%);
            }

            &.step-current {
                transform: translateX(0);
            }

            &.step-next {
                transform: translateX(+100%);
            }

            .step-title {
                background: var(--step-modal-secondary-color);
                color: var(--step-modal-primary-color);
                padding: 10px;
                display: flex;
                justify-content: center;
                font-weight: bold;
            }

            .step-content {
                background-color: #F2F1F6;
                flex-grow: 1;
                padding: 1.5rem;
                overflow: hidden;
            }

        }

    }

    .modal-footer {

        ap-step-modal-nav {
            --step-custom-element-primary-color: var(--step-modal-primary-color) !important;
            margin-right: 2rem !important;
        }
        
        .required-fields-text {
            font-size: 10px;
            color: #878787;
            margin-right: auto;
        }

        .step-modal-footer-buttons {
            display: flex;

            ap-button {
    
                &#btn-next,
                &#btn-prev {
                    --color: var(--step-modal-primary-color) !important;
                    --background: var(--step-modal-secondary-color) !important;
    
                    --hover-color: color-mix(in srgb, var(--step-modal-primary-color) 93%, black) !important;
                    --hover-background: color-mix(in srgb, var(--step-modal-secondary-color) 93%, black) !important;
    
                    --active-color: color-mix(in srgb, var(--step-modal-primary-color) 93%, black) !important;
                    --active-background: color-mix(in srgb, var(--step-modal-secondary-color) 93%, black) !important;
    
                    --disabled-color: color-mix(in srgb, var(--step-modal-primary-color) 70%, white) !important;
                    --disabled-background: color-mix(in srgb, var(--step-modal-secondary-color) 70%, white) !important;
    
                }
            }

            .separator {
                background-color: #dedede;
                height: calc(100% - 20px);
                margin: 10px;
                width: 2px;
            }
        }
    }

}