ap-price-bill-provider {

    .tva-block-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 10px;
    }

    .tva-block {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .line {
        display: grid;
        grid-template-columns: auto 60% 80px;
        grid-template-rows: 50px;
        gap: 10px;

        .col-right,
        .col-center,
        .col-left {
            border-bottom: 1px solid #eaeaea;
            align-items: center;
            display: flex;
            padding-right: 10px;
            padding-left: 10px;
        }

        .col-center {
            display: grid;
            grid-template-columns: auto 160px 80px;
            gap: 20px;
        }

        .col-right {
            margin-left: -10px;
            justify-content: end;
        }
    }

    .line-header {

        .col-right,
        .col-center,
        .col-left {
            background-color: #EEEEEE;
            border-bottom: none;
        }

        .col-left {
            border-radius: 5px;
        }

        .col-center{
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        .col-right {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    .line-header:has(+ div) {
        margin-bottom: 20px;
    }

    .add-tva-button {
        text-align: right;
        margin-bottom: 20px;
    }
}