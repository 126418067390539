.modal-addEditCompanies {

    .information {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 55px);

        .item-name {
            grid-area: 1 / 1 / 2 / 3;
        }

        .item-capital {
            grid-area: 2 / 1 / 3 / 2;
        }

        .item-RCS {
            grid-area: 2 / 2 / 3 / 3;
        }

        .item-SIREN {
            grid-area: 3 / 1 / 4 / 2;
        }

        .item-SIRET {
            grid-area: 3 / 2 / 4 / 3;
        }

        .item-TVACode {
            grid-area: 4 / 1 / 5 / 2;
        }

        .item-APE {
            grid-area: 4 / 2 / 5 / 3;
        }

    }

    .hours-morning {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(1, 55px);

        .item-start {
            grid-area: 1 / 1 / 2 / 2;
        }

        .item-end {
            grid-area: 1 / 2 / 2 / 3;
        }
    }

    .hours-afternoon {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(1, 55px);

        .item-start {
            grid-area: 1 / 1 / 2 / 2;
        }

        .item-end {
            grid-area: 1 / 2 / 2 / 3;
        }
    }

    .hours-meal {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 55px);

        .item-meal {
            grid-area: 1 / 1 / 2 / 2;
        }

        .item-midday {
            grid-area: 1 / 2 / 2 / 3;
        }

        .item-evening {
            grid-area: 1 / 3 / 2 / 4;
        }
    }

    .companies-modal-block-buttons {
        display: flex;
        gap: 0.5rem;

        .btn-settings {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .companies-modal-block-body {
        display: flex;
        flex-direction: column;
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }

    .companies-modal-inputs-row {
        display: flex;
        padding: 0.5rem 0;
        gap: 1.5rem;
    }

    .companies-modal-block {
        &:not(:last-child) {
            margin-bottom: 2rem;
        }

        display: flex;
        flex-direction: column;
    }

    .companies-modal-block-header {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #DADADA;
        height: 40px;
        display: flex;
        align-items: center;

        .companies-modal-invalid-message {
            color: #dc3545;
        }
    }

    .companies-modal-block-title {
        font-size: 22px;
        font-weight: 500;
    }

    #company-logo-file {
        border-radius: 0.5rem !important;
    }

    #company-logo-file-label {
        cursor: pointer;
        margin-bottom: 0;
    }

    #company-logo-preview {
        height: 200px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 1rem;
        align-items: center;
        color: #7c7c7c;
        border: 1px dashed #d1d1d1;
        border-radius: 0.5rem;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    #company-address-list {
        height: 250px;
        width: 100%;
    }

    #company-bank-list {
        height: 250px;
        width: 100%;
    }

}