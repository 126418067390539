ap-files-readonly {

    display: flex;
    flex-direction: column;
    gap: 10px;

    .item {
        background-color: #363A45;
        color: white;
        padding: 10px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        cursor: pointer;
        width: 100%;
    }

}