ap-navbar-clock {

    height: 42px;
    padding: 0px 0.75rem;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 8px;

    font-size: 14px;
    line-height: 17px;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

    #date-time {

        display: flex;

        flex-direction: column;
        align-items: center;

        gap: 0.25rem;

        #date {
            font-weight: 400;
        }

        #time {
            font-weight: 500;
        }
    }
}