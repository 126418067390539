.modal-add-edit-category {

    .modal-dialog {
        height: 90%;

        .modal-content {
            height: 100%
        }

        .modal-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #f2f1f7;
            padding-top: 2rem;
        }

        label {
            font-weight: bold;
        }

        .container-modal-body {
            width: 1000px;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .container-name {
            padding: 20px;
            border-radius: 10px;
            background: white;
            box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.08);
        }

        .container_list {
            flex-grow: 1;

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 100%;
            overflow: hidden;
            gap: 20px;

            .list {

                display: flex;
                flex-direction: column;
                padding: 20px;
                border-radius: 10px;
                background: white;
                box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.08);

                .list-content {
                    width: 100%;
                    overflow: auto;

                    flex-grow: 1;

                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .item {
                        padding: 10px;
                        border: 1px solid #dedede;
                        border-radius: 5px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;

                        .text {
                            flex-grow: 1;
                        }

                        &.selected {
                            position: relative;
                            background-color: #F5F9FF;
                        }
                    }
                }

                .add {
                    margin-top: 20px;
                }
            }
        }

    }
}