.modal-export-dashboard {

    .modal-body {
        background-color: #f2f1f7;
        padding-top: 2rem;
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .label {
        font-weight: bold;
        display: flex;
        flex-direction: row;
    }

    .header {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 300px;
    }

    .card {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.08);
        padding: 20px;
        gap: 10px;
    }

    .content {
        flex-grow: 1;

        .container-selectall {
            display: flex;
            flex-direction: row;
            gap: 10px;
            margin-left: auto;
            font-weight: normal;
        }

        #columnsToPrint {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            flex-direction: column;
            overflow: auto;
        }

    }

}