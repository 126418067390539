@import "../upload-file.scss";

ap-upload-photo {

    &.no-photo {
        .photo {
            display: none !important;
        }
    }

    &.no-invalid {
        .invalid {
            display: none !important;
        }
    }

    .base64 {
        display: none;
    }

    .upload-file {

        .invalid {
            display: flex;
            justify-content: center;
            align-items: center;

            color: #D4D7DB;

            position: absolute;
            top: 5px;
            left: 5px;

            height: 88px;
            width: 88px;

            border: 1px solid #EAEAEA;
        }

        .photo {
            position: absolute;
            top: 5px;
            left: 5px;

            height: 88px;
            width: 88px;

            border: 1px solid #EAEAEA;
            background: white;
            color: #D4D7DB;

            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-height: 100%;
                max-width: 100%;
            }

            &:has(img[src=""]) {
                .loader-image {
                    display: block;
                }
            }

            .loader-image {
                display: none;
            }

            .delete {
                position: absolute;

                cursor: pointer;

                color: #D4D7DB;
                right: -27px;
                top: -5px;
                font-size: 25px;

                &:hover {
                    color: #a7aaaf;
                }
            }
        }
    }

}

.loading {
    ap-upload-photo {
        &.cell-loading {
            &>* {
                visibility: hidden;
            }
        }
    }
}