ap-traceability {
	display: flex;
	align-items: center;
	flex-grow: 1;
	gap: 5px;

	.item {
		display: flex;
		align-items: center;

		border-radius: 10px;

		color: #6e6e6e;

		padding: 3px 8px 3px 8px;

		.text {
			font-size: 13px;
			margin-left: 5px;
			display: flex;
			width: 0;
			overflow: hidden;
			white-space: nowrap;
		}

		&:hover {
			&:not(:has(.icon-spin)) {
				.text {
					width: auto;
					overflow: inherit;
				}
			}
		}
	}

	.separator {
		height: 10px;
		width: 1px;
		background-color: #6e6e6e;
		opacity: 0.3;
	}

}

.mobile {
	ap-traceability {
		display: none !important;
	}
}