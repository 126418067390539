@import "./mobile.controller-page-id.scss";
@import "./mobile.modal.scss";
@import "./mobile.tabs.scss";
@import "./mobile.select2.scss";

.mobile {


    .navbar-bottom {
        position: absolute;
        display: flex;
        width: 100%;
        height: 3.25rem;
        top: calc(100% - 3.25rem) !important;
        color: black !important;

        #navbar-right-content {

            position: absolute;
            color: black !important;
            right: 0;
            bottom: 0;
            height: 100%;
            display: flex;
            align-items: center;

            .circle-btn {
                color: black !important;
            }
        }

        #navbar-left-content {
            position: absolute;
            color: black !important;
            left: 0;
            bottom: 0;
            height: 100%;
            display: flex;
            align-items: center;
        }

        .btn-navbar {
            color: black !important;
        }
    }

    #back,
    #forward {
        color: black !important;
    }

    .circle-btn {
        margin-left: 0 !important;
    }

    /*#page-title {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important;
    }*/

    .title {
        width: auto !important;
    }

    .add-mobile {
        position: fixed;

        bottom: 3.25rem;
        right: 0;

        margin: 0.5rem;
        z-index: 1;

        height: 45px;
        width: 45px;

        font-size: 23px;
    }

    .modal-body {

        .add-mobile {
            bottom: 5.25rem;
            right: 1rem;
        }
    }

    #container-search {

        position: relative;
        width: 100%;
        height: 32px;
        margin: 0px 1rem 0px 1rem;
        font-size: 15px;

        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 38px;
            border: 1px solid #dfdfdf;
            height: 100%;
            font-weight: bold;
            background: #e0e0e0;
            border-radius: 0 10px 10px 0;
            z-index: 1;
        }

        input {
            outline: 0;
            width: calc(100% - 38px);
            height: 100%;
            background: white;
            line-height: 40px;
            border: 1px solid #dfdfdf;
            border-radius: 10px 0 0 10px;
            appearance: none;
            z-index: 1;
            position: relative;
            border-right: 0;
            padding: 10px;
        }
    }

    #page-title {
        background-color: #f2f2f2 !important;
        color: black !important;

        min-height: unset !important;


        &.page-title-view-edit {

            padding: 1rem !important;

            min-height: auto !important;

            .title-container {
                border-radius: 10px;
                background-color: white;
                border: 1px solid #dfdfdf;
                display: flex;
                align-items: center;
                width: 100%;
                padding-left: 1rem;

                #title {
                    font-size: 20px;
                    font-weight: bold;
                    margin-left: 0;
                }
            }
        }
    }

    #page-content {
        padding: 0px
    }

    #page-content,
    .modal-body {
        background-color: #F2F2F2 !important;
		padding: 1rem;
		width: 100%;
		overflow-x: hidden !important;
    }

    .card-mobile {
        display: flex;

        background-color: white;

        padding: 1rem;
        margin-bottom: 0.5rem;

        border-radius: 10px;
        box-shadow: #0000000a 0px 1px 1px 0px;
    }

    .mobile-quote-state {

        display: flex;
        align-items: center;

        &[data-state="En Cours"],
        &[data-state="Terminé"],
        &[data-state="À Valider"] {
            color: black;
        }

        &[data-state="Envoyé"] {
            color: #cb8ad6;
        }

        &[data-state="Accepté"] {
            color: #62cb66;
        }

        &[data-state="Refusé"] {
            color: #e07f7f;
        }

        &[data-state="Attente Commande"] {
            color: #FFCC80;
        }

        &[data-state="Révisé"] {
            color: #e07f7f;
        }
    }

    .tabs-container {

        width: 100%;
        height: initial !important;
        background-color: #E1E1E1;
        margin: 1rem;
        margin-bottom: 0.5rem;
        border-radius: 10px;

        button {
            color: black;
            border: 1px solid #dfdfdf;
            padding: 7px;
            margin: 0;
            width: 100%;

            box-shadow: none;
            border-radius: 10px;

            font-size: 13px;
            font-weight: bold;
            text-align: center;
            transition: 0.6s;

            &.tab-active {
                background: white;
            }

            &.btn-tab:hover {
                box-shadow: none !important;
            }

            &.btn-grey-300 {
                --hover-background: #e0e0e0;
                --active-background: #e0e0e0;
            }
        }

    }

    #app-content {
        padding-bottom: 50px;
    }

    #floating-modals-container {
        top: 0 !important;
    }

    [display="desktop"] {
        display: none !important;
    }

}

.desktop {
    [display="mobile"] {
        display: none !important;
    }
}