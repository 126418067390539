ap-create-order-brand-line {
    background-color: #F4F4F5;
    font-size: 14px;
    font-weight: 700;
    color: #4E4E4E;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    border-radius: 5px;
    user-select: none;
}