.editCellRenderer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 10px;

    ap-button {
        margin-top: 5px;
    }
}