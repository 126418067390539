ap-quantity {

    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;

    border: 1px solid #D4D7DB;
    border-radius: 5px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);

    user-select: none;

    background-color: white;

    .subtract {
        display: inline-flex;
        border-radius: 5px !important;
    }

    input {
        display: inline-block;
        border: none;
        outline: none;
        text-align: center;
        height: 100%;
        width: calc(100% - 2 * 32px);
        padding: 0;

        appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }

    .add {
        display: inline-flex;
        border-radius: 5px !important;
    }

}