:root {

    --active-tabs-color: #3475f8;
    --active-tabs-background: rgba(52, 117, 248, .15);

    --hover-tabs-color: black;
    --hover-tabs-background: rgba(52, 117, 248, .15);

    --border-color-item-tabs-separator: rgb(242, 241, 247);

    --height-item-tabs: 43px;
    --border-radius-item-tabs: 8px
}

#page-title-settings {
    background-color: white;

    display: grid;
    grid-template-columns: 250px auto;
    height: 50px;
    align-items: center;
    font-weight: 600;
    padding: 0 20px;
    grid-gap: 10px;
    gap: 10px;

    .title-list {
        padding-right: 20px;
        border-right: 1px solid #D9D9D9;
    }

    .title-content {
        padding: 10px;
    }
}

.page-settings {

    display: grid;
    grid-template-columns: 250px auto;

    gap: 10px;
    padding: 30px;

    .list {
        border-right: 1px solid #D9D9D9;

        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-right: 20px;

        overflow: auto;

        .title {
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
        }

        .links {
            background: #FFFFFF;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.08);
            border-radius: var(--border-radius-item-tabs);

            .link {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 14px;
                cursor: pointer;
                padding-left: 15px;

                height: var(--height-item-tabs);
                border-bottom: 1px solid var(--border-color-item-tabs-separator);

                &:hover {
                    color: var(--hover-tabs-color);
                    background: var(--hover-tabs-background);
                }

                &.active {
                    color: var(--active-tabs-color);
                    background: var(--active-tabs-background);
                }

                &:first-child {
                    border-radius: var(--border-radius-item-tabs) var(--border-radius-item-tabs) 0px 0px;
                }

                &:last-child {
                    border-bottom: 0;
                    border-radius: 0px 0px var(--border-radius-item-tabs) var(--border-radius-item-tabs);
                }
            }
        }

        ap-button {
            margin-left: auto;
            padding: 5px;
        }
    }

    .content {
        overflow: hidden;

        &>div {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            overflow: hidden;
        }

        .grid-container {
            grid-template-rows: minmax(0, 1fr);
        }

        .title-buttons {
            display: flex;
            align-items: center;
            margin-left: auto;

            position: absolute;
            top: 7px;
            right: 10px;
        }

        .content-settings {
            overflow: auto;
            flex-grow: 1;
            padding-left: 1rem;

            form {
                height: 100%;
                width: 100%;
            }
        }

        .h1-settings {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        .h2-settings {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .h3-settings {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 10px;
        }

    }

}