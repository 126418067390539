// (va = vertical accordion)
.va-tab-container {
    box-shadow: #0000001a 0px 1px 2px 0px;
    padding: 1.5rem 1rem;
    cursor: pointer;
    height: 100%;
    background-color: white;
    width: 60px;
    border-radius: 10px;
}

.va-title-container {
    height: 100%;
    display: flex;
    overflow: hidden;
    writing-mode: vertical-lr;
}

.va-tab-title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: auto;
}

.va-tab-subtitle {
    font-weight: 600;
    font-size: 17px;
}