ap-edit-categories {

    background: #FFFFFF;
    border-radius: 8px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .header {
        padding: 1.5rem;

        .title-categories {
            font-weight: bold;
            margin-bottom: 0.5rem;
            font-size: 1rem;
        }
        border-bottom: 1px solid #E5E5E5;
    }

    .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 1.5rem;
        overflow: scroll;

        div {
            display: flex;
            flex-direction: row;

            &.highligth {
                color: var(--step-modal-primary-color);
            }

            ap-checkbox {
                margin-left: calc(25px * var(--level));

                --size: 20px;
                --color: white;
                --background-color: white;
                --background-color-checked: var(--step-modal-primary-color);
            }

            ap-button {
                transition: transform 0.3s;
                transform: rotate(180deg);
                height: 20px !important;
                width: 20px !important;
                min-height: 20px !important;
                min-width: 20px !important;
            }

            &.active {
                ap-button {
                    transform: rotate(0);
                }
            }


        }


    }

    &.invalid {
        border-color: var(--color-form-invalid);
    }
}