.confirmation-popover {
    font-family: Roboto;

    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 10%) 0 0 25px 0;
    border: 1px solid #afafaf;

    .popover-header {
        padding: 1em;
        font-weight: 500;
        display: flex;
        place-content: center;
    }

    .popover-body {
        gap: 0;
        justify-content: space-around;
        align-items: center;

        .btn {
            background-color: transparent;
            border-radius: 7px;
            width: 45px;

            &:hover,
            &:active {
                background-color: #F2F1F7;
                transition: background 0.2s;
            }
        }

        .reject {
            ap-icon {
                color: #F05050;
            }
        }

        .resolve {
            ap-icon {
                color: #2A59FE;
            }
        }
    }
}