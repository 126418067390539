ap-dashboard-user {
    display: flex;
    gap: 10px;
    align-items: center;

    .badge {
        background-color: var(--background-color, #E5E1F8);
        color: var(--color, #755ee9);
        border-radius: 50%;
        display: flex;
        justify-items: center;
        align-items: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        max-width: 35px;
        min-width: 35px;
        font-size: 13px;
    }
}