ap-multi-input {
    display: flex;

    --height: calc(1.5em + 0.75rem + 2px);
    --border-radius: 5px;
    --border-color: #D4D7DB;

    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);

    line-height: calc(var(--height) - 10px);

    padding: 4px 10px;
    overflow: hidden;
    width: 100%;

    .content {

        white-space: nowrap;
        display: flex;


        .input {
            min-width: 2ch;
            outline: none;
            cursor: text;

            text-align: right;

            &:empty {
                &::before {
                    content: "__";
                }
            }
        }

        .separator {
            font-weight: bold;
            margin: 0 5px;
        }

    }
}