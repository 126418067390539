ap-product-image-previewer {

    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
    border-radius: 5px;

    #loading-spinner {
        ap-icon {
            font-size: 90px !important;
        }
    }

    &.displayImage {

        #loading-spinner,
        #img-invalid,
        #img-default {
            display: none;
        }

        #img-preview {
            display: inherit;
        }

    }

    &.displayDefault {

        #loading-spinner,
        #img-preview,
        #img-invalid {
            display: none;
        }

        #img-default {
            display: inherit;
        }

        border: 1px dashed #c9c9c9;
    }

    &.displayInvalid {

        #loading-spinner,
        #img-preview,
        #img-default {
            display: none;
        }

        #img-invalid {
            display: inherit;
        }

        border: 1px dashed #c9c9c9;
    }

    &.displaySpinner {

        #img-preview,
        #img-default,
        #img-invalid {
            display: none;
        }

        #loading-spinner {
            display: inherit;
        }

        border: 1px dashed #c9c9c9;
    }

    #img-preview,
    #img-invalid,
    #img-default {
        display: none;
    }

}