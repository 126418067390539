@import "./icons/autoprog-icons.css";
@import "./theme.scss";
@import "./select2";
@import "./switch";
@import "./popover";
@import "./loader.scss";
@import "./print.scss";
@import "./scrollbar.scss";
@import "./skeletonLoader.scss";
@import "./theme/index.scss";

@import "./ap-select-address.scss";
@import "./ap-select2-button.scss";
@import "./ap-deadline-payment.scss";

@import "./settings-page.scss";

@import "./modals/change-state.scss";


@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}


body {
	font-family: Roboto;
	font-size: 1rem;
}

//TODO: à retirer avec la prochaine version 
//Attribut popover du thème surchargé pour être compatible avec la nouvelle version de Chrome
[popover] {
	display: flex;
	position: relative;
	border: none;
	padding: 0;
	overflow: hidden;
	background: transparent;
	color: inherit;
	margin: 0;
}

.custom-checkbox {
	display: flex;

	.custom-control-label {
		display: flex;
		align-items: center;
	}
}

/* par defaut la couleur est noir */
:not(:root):fullscreen::backdrop {
	background: white !important;
}

button {
	&[disabled] {
		cursor: not-allowed !important;
	}
}

#listQuotes {
	max-height: calc(96px + 14px + 0.375rem);
	margin-bottom: 0;
	padding-left: 0;
	overflow-y: auto;
	list-style: none;
}

/**
* INPUT
*/

select {
	&.form-control-plaintext {
		&:disabled {
			appearance: none;
			opacity: 1;
		}
	}
}

.form-control:not(#login-pass):not(#input-login) {
	color: #495057 !important;
	border: 1px solid #dee2e6 !important;
	border-radius: 4px;
	box-shadow: none;
	outline: none;

	&:focus {
		border: 1px solid #80bdff !important;
		box-shadow: none;
	}

	&.is-invalid,
	&:invalid,
	&.invalid {
		background-image: none;
		border-color: var(--color-form-invalid) !important;
	}
}

input[type="file"] {
	color: #495057 !important;
	border: 1px solid #ced4da !important;
	border-radius: 0 !important;
	width: 100%;
	height: auto !important;
	padding: 0.5rem !important;
}

::placeholder {
	color: rgb(170, 170, 170) !important;
	opacity: 1;
}

/**
* FIN INPUT
*/

/*
TODO: A supprimer quand tout sera des pages
*/

.border-4 {
	border-left-width: 4px !important;
}

/**
* BOUTON
*/

.btn:not([tab-link]) {
	--size: 2.285rem;
	font-size: initial;
	border-radius: 0.5rem !important;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.btn-no-rounded {
	border-radius: 0px !important;
}

.btn-print-modal {
	//btn-light-blue-500
	--background: #03a9f4;
	--color: white;
	--border-color: white;
	--hover-background: #0096e1;
	--hover-color: white;
	--active-background: #0090db;
	--active-color: white;
}

.btn-print-modal {
	i {
		padding-right: 0.5rem !important;
	}
}

/**
a suppr
*/
.btn-export-csv {
	//btn-blue-grey-500
	--background: #607d8b;
	--color: white;
	--border-color: white;
	--hover-background: #4d6a78;
	--hover-color: white;
	--active-background: #476472;
	--active-color: white;
}

.btn-export-pdf {
	//btn-red-700
	--background: #d32f2f;
	--color: white;
	--border-color: white;
	--hover-background: #c01c1c;
	--hover-color: white;
	--active-background: #ba1616;
	--active-color: white;
}

.btn-settings {
	//btn-grey-300
	--background: #e0e0e0;
	--color: black;
	--border-color: black;
	--hover-background: #cdcdcd;
	--hover-color: black;
	--active-background: #c7c7c7;
	--active-color: black;
}

.btn-action {
	--background: #01579b;
	--color: white;
	--border-color: transparent;
	--hover-background: #004488;
	--hover-color: white;
	--active-background: #003e82;
	--active-color: white;

	i {
		padding-right: 0.5rem !important;
	}
}

.btn-add {
	//btn-green-600
	--background: #43a047;
	--color: white;
	--hover-background: #308d34;
	--hover-color: white;
	--active-background: #2a872e;
	--active-color: white;

	gap: 0.5rem !important;
}

.btn-label {
	padding: 0;
	border: none;
	background: transparent;
	color: #3475F8;
	font-size: 12px;
}

.btn-previous-modal {
	--background: #3475f8;
	--color: white;
	--border-color: white;
	--hover-background: #1762fa;
	--hover-color: white;
	--active-background: #1762fa;
	--active-color: white;

	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;

	i {
		padding-right: 0.5rem !important;
	}
}

.btn-validate-modal {
	//btn-deep-purple-600
	--background: #5e35b1;
	--color: white;
	--border-color: white;
	--hover-background: #4b229e;
	--hover-color: white;
	--active-background: #451c98;
	--active-color: white;

	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;

	i {
		padding-right: 0.5rem !important;
	}
}

.btn-close-modal {
	//btn-transparent
	--background: transparent;
	--color: black;
	--border-color: transparent;
	--hover-background: rgba(97, 97, 97, 0.2);
	--hover-color: black;
	--active-background: rgba(97, 97, 97, 0.2);
	--active-color: black;

	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;
	margin-right: 0.5rem !important;
}

.btn-save-modal {
	//btn-green-600
	--background: #43a047;
	--color: white;
	--border-color: white;
	--hover-background: #308d34;
	--hover-color: white;
	--active-background: #2a872e;
	--active-color: white;

	i {
		padding-right: 0.5rem !important;
	}
}

.btn-transparent {
	background: transparent !important;
	border: none !important;
	color: black !important;

	&.text-white {
		color: white !important;
	}

	&:hover {
		background: rgba(97, 97, 97, 0.2) !important;
	}

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	&#btn-return {
		&:hover {
			background: rgba(255, 0, 0, 0.7) !important;
		}
	}
}


.btn-rounded-transparent {
	border-radius: 10px;
	border: 0;

	background: transparent;

	font-size: 18px;

	height: max-content;
	width: max-content;

	display: flex;
	align-items: center;

	padding: 0.5rem;

	cursor: pointer;

	&:active,
	&:hover {
		background-color: #F2F1F7;
	}

	&:disabled {
		cursor: not-allowed;
	}

	&.dark-mode {
		color: white;

		&:active,
		&:hover {
			background-color: rgba(255, 255, 255, 0.1) !important;
		}
	}
}

.btn-no-background {
	background-color: transparent !important;
}

.btn-price-header {
	color: white;
	background-color: #4caf50;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 3px;
	padding-left: 3px;
	height: 18px;
	width: 19px;
	border-radius: 100%;

	&:hover {
		background-color: #666666;
		color: white;
	}
}

.icon-helper {
	background-color: transparent;
	color: #a5a5a5;
	font-size: 1.3em;
}

.circle-btn {
	$size: 42px;
	margin-left: 10px;
	padding: 0;
	color: white;
	width: $size;
	height: $size;
	position: relative;
	border-radius: 100%;
	background: none !important;
	border: none !important;

	&:hover {
		background-color: rgba(255, 255, 255, 0.5) !important;
	}

	&:active,
	&.dropdown.show {
		background-color: white !important;
		color: black;
	}

	.icon {
		text-align: center;
		line-height: $size;
	}

	&.custom {
		color: rgb(37, 37, 37);

		&:hover {
			background-color: rgba(0, 0, 0, 0.1) !important;
		}

		&:active,
		&.dropdown.show {
			background-color: rgb(0, 0, 0) !important;
			color: white !important;
		}
	}
}

button.close {
	font-size: 2rem;
}

/**
* FIN BOUTON
*/

/**
* DIVERS
*/

#page-title {
	min-height: 3.25rem !important;
}

.cell-wrap-text {
	white-space: normal !important;
}

.badge-legend {
	$size: 18px;
	height: $size;
	width: $size;
	border-radius: 100%;
	margin-right: 5px;
}

.badge-legend.bg-white {
	border: 1px solid #a5a5a5;
}

.custom_h4 {
	font-size: 1.2rem;
	font-weight: bold;
	color: #212121;
}

.custom_h2 {
	font-size: 1.6rem;
}

// Zone pour apercu le photo
.image-preview-zone {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: middle;
	border: dashed 1px var(--ap-grey-400);
	border-radius: 0.25rem;
	color: var(--ap-grey-400);
	padding: 1rem;
	min-height: 100px;
}

#preview {
	padding: 0.1rem;
	max-height: 100px;
}

.border-radius {
	border-radius: 10px;
}

/**
* FIN DIVERS
*/

.icon-btn {
	&:hover {
		color: var(--ap-blue-600);
	}
}

#header-grid,
#footer-grid {
	width: 100%;
	display: flex;
	gap: 10px;
}

#header-grid {
	padding-bottom: 1rem;
	gap: 0.5rem;

	#container_button_header {
		display: flex;
		margin-left: auto;
		gap: 5px
	}
}

/*
* NAVBAR MODIF
*/

.custom-badge {
	position: absolute;

	top: 0px;
	right: 0px;

	border-radius: 45rem;

	background: red;
	color: white;

	display: flex;
	align-items: center;
	justify-content: center;

	min-width: 14px;
	height: 14px;

	font-size: 10px;
	padding: 4px;

	&.yellow {
		background: rgb(255, 187, 0);
		color: rgb(0, 0, 0);
	}
}

#navbar-right-content {
	height: 42px;
}

#aside .nav li>a .icon {
	font-size: 1.2rem;
}

.navbar-light .navbar-toggler {
	color: rgb(0, 0, 0);
	font-weight: bold;
	border: none;
	background-color: none;
	border-width: 2px;
	padding-right: 0.3rem;
	padding-left: 0.3rem;
	width: 100%;

	&:hover {
		background-color: #7ebcff;
		color: white !important;
	}
}

.nav-link {
	&:hover {
		background-color: #7ebcff;
		color: white !important;
	}

	&.active {
		background-color: #007bff;
		color: white !important;
	}
}

/* BOUTON FILTRE */
ap-filter-button {
	display: flex;
	align-items: center;
	gap: 0.25rem;
	padding-left: 0.25rem;
	padding-right: 0.25rem;

	.dropdown-menu {
		min-width: max-content;
		padding: 25px 20px;
		border-radius: 10px;
		margin-top: 10px;

		.dropdown-content {
			display: flex;
			gap: 20px;
		}

		.title {
			color: var(--ap-grey-600);
			padding-left: 10px;
			padding-right: 10px;
		}

		.header-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-top: 3px;
			padding-bottom: 3px;
			padding-left: 10px;
			padding-right: 10px;
			border-radius: 10px;
			gap: 30px;

			.header-item-title {
				color: black;
				font-weight: 500;
				font-size: 16px;
			}

			.btn-clear-filter {
				width: 24px;
				height: 24px;
				min-width: 23px;
				min-height: 24px;
				margin-right: 3px;

				ap-icon {
					font-size: 16px;
				}
			}
		}

		.item {
			display: flex;
			align-items: center;
			padding-top: 3px;
			padding-bottom: 3px;
			padding-left: 10px;
			padding-right: 10px;
			border-radius: 10px;
			margin: 2px 0;

			.item-icon-label {
				display: flex;
				align-items: center;
				border-radius: 100px;
				padding: 3px 15px;
				margin-left: -15px;
			}

			.item-label {
				margin: 0;
				display: initial;
			}

			.custom-control {
				margin-left: auto;
				padding-left: 40px;
			}

			&.checkbox-item {
				color: var(--ap-grey-600);
			}
		}
	}

	.custom-checkbox {
		.custom-control-label {
			&::before {
				border-radius: 2px;
			}
		}
	}


}

#default-value-print {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

/* -------------------------------------------------------------------------- */

/**
* SIDE MENU LIST
*/
.side-block {
	height: 100%;
	border-right: 1px solid #dee2e6;

	.title {
		font-size: 1.2rem;
		padding-top: 1rem;
		padding-bottom: 0.5rem;
		border-bottom: 1px solid rgb(158, 158, 158);
		margin-bottom: 0.5rem;

		.small-plus-button {
			padding: 0 12px;
			height: 20px;
			font-size: 1rem;
			border-radius: 20px;
			background-color: #e6e6e6;
		}
	}
}

/**
* FIN SIDE MENU LIST
*/

/**
* Settings form
*/
#general-settings-form {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
}

.modal-body {
	.title {
		display: flex;
		align-items: center;
		font-weight: bold;
		font-size: 1.5rem;
		line-height: 1.2;
		color: black;
		padding-bottom: 0.5rem !important;
		padding-top: 0.5rem !important;

		.icon {
			cursor: pointer;
		}
	}
}

#page-content {
	padding: 20px
}

.grid-container {
	display: grid;
	gap: 20px;
	height: 100%;
	width: 100%;

	.card {
		border-radius: 10px;
		height: 100%;
		border: none !important;
		box-shadow: #0000001a 0px 1px 2px 0px;
	}

	.card-header {
		font-size: 0.9rem;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		background: white;
		border: none;
		font-weight: bold;
		display: flex;
		align-items: center;
	}

	@media only screen and (min-device-width: 720px) and (max-device-width: 1280px) {
		.card-body {
			font-size: 0.8rem !important;
		}
	}

	.card-body {
		font-size: 0.9rem;
		padding-top: 0;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.tab-card {
		#scroll-container {
			width: 100%;
			max-width: 100%;
			flex-grow: 1;
			overflow: auto;
		}

		.card-body {
			padding: 0;
		}

		.title {
			display: flex;
			align-items: center;
			font-weight: bold;
			font-size: 1.5rem;
			line-height: 1.2;
			color: black;
			padding-bottom: 0.5rem !important;
			margin-bottom: 0.5rem !important;
			border-bottom: 1px solid #dee2e6 !important;

			.icon {
				cursor: pointer;
			}
		}

		.menu {
			display: flex;
			flex-direction: column;
			background-color: #f7f7f7;

			width: calc(35px + 10px * 2);
			min-width: calc(35px + 10px * 2);
			overflow-x: hidden;

			border-radius: 10px 0px 0px 10px;
			border: 0;

			white-space: nowrap;
			transition: width 0.3s ease-out, min-width 0.3s ease-out;

			&.active {
				width: 20rem;
				min-width: 20rem !important;
				overflow: auto !important;
			}

			.title {
				padding: calc(1rem + 7px);
				padding-bottom: 1rem !important;
				overflow: hidden;
				gap: 1.25rem;
			}

			.tab-page-list {
				scrollbar-width: 5px;
				display: block;
				border: none !important;
				padding: 10px;

				li {
					width: 100%;
					list-style-type: none;

					margin-top: 5px;
					margin-bottom: 5px;

					a {
						min-height: 35px;
						width: 100%;
						padding-right: 10px;
						border-radius: 5px;
						color: #6e6e6e;
						display: flex;
						align-items: center;
						gap: 1rem;

						div.icon-container {
							min-height: 35px;
							min-width: 35px;
							display: flex;
							align-items: center;
							justify-content: center;
							position: relative;

							.icon {
								font-size: 1rem !important;
							}

							ap-icon {
								font-size: 1.3rem !important
							}

							.nav-icon-badge {
								position: absolute;
								padding: 0.25em;
								top: 0;
								right: 0;
								border-radius: 100%;
								font-size: 0.75em;
								font-weight: 700;
								line-height: 1;
							}
						}

						span {
							text-overflow: ellipsis;
							overflow: hidden;
						}

						&:hover {
							text-decoration: none;
							background-color: #77a5ff;
							color: white;
						}

						&.active {
							color: white;
							background-color: #3475f8;

							&:after {
								content: "\203A";
								margin-left: auto;
								transform: scale(1.5);
								font-weight: bold;
							}

							.badge {
								color: white;
							}
						}
					}
				}
			}
		}
	}
}

#fullscreen {
	&.fullscreen {
		grid-row-start: 1 !important;
		z-index: 1
	}
}

/**
a supprimer quand toute les pages sueront migrer
*/
.page-card-label {
	font-weight: 600;
	color: black;
	white-space: nowrap;
}

.page-card-value {
	color: #6e6e6e;

	&.price-value {
		white-space: nowrap;
		margin-left: auto;
	}
}


/**
* Conteneur des modales flottantes (exemples : notifications, documents ouverts)
*/

#floating-modals-container {
	position: fixed;
	right: 0;
	top: 4rem;
	display: flex;
	bottom: 0;
	z-index: 1200;
}

// Empêche la selection de texte
.text-not-selectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.warning-badge {
	display: flex;
	background-color: var(--ap-orange-100);
	color: var(--ap-orange-800);
	border-radius: 25px;
	padding: 6px 20px;
	font-weight: normal;
	white-space: nowrap;
	align-items: center;
	user-select: none;
}