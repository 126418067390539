ap-edit-tags-readonly {

    display: block;
    width: 100%;

    .content {

        display: flex;
        gap: 5px;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }

}

ap-badge-edit-tags-readonly {
    display: flex;
    background-color: #252A38;
    border-radius: 20px;
    color: #EAE8EC;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 20px;
    font-size: 12px;
    width: fit-content;
}

.ag-cell {
    &:has(ap-badge-edit-tags-readonly) {
        span {
            display: flex;
            gap: 5px;
        }
    }
}